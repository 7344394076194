<template>
    <span @click="handleClipboardClick" :id="token" class="boxed" v-if="label">
        {{label}}
    </span>
</template>
<script>
    export default {
        props: ['label'],
        data() {
            return {
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        methods: {
            handleClipboardClick() {
                this.selectText(this.token)
            },
            selectText(element) {
                let doc = document
                let text = doc.getElementById(element)
                let range = null
                let selection = null;

                if (doc.body.createTextRange) {
                    range = document.body.createTextRange();
                    range.moveToElementText(text);
                    range.select();
                } else if (window.getSelection) {
                    selection = window.getSelection();
                    range = document.createRange();
                    range.selectNodeContents(text);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        }
    }
</script>
<style scoped>
    .boxed {
        border: 1px dashed deepskyblue;
    }
    .boxed:hover{
        cursor: pointer;
    }
</style>
