import {getMenuData as baseMenu} from "./base.menu";

/**
 * This is the navigation for all Suppliers
 */
export function getMenuData(self) {

    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        // carrier
        {
            title: self.$t('topBar.navigations.categories.carrier'),
            key: 'carrier',
            name: 'carrier',
            category: 1,
        },
        {
            title: 'ATN',
            key: 'module.carrierSupplierDeliveryForecasts',
            name: 'module.carrierSupplierDeliveryForecasts',
            url: '/carrier/s/order/drafts',
            icon: 'fe fe-circle',
            hasAnyPermission: ['orderdraftscanseechartsc', 'orderdraftscanseelistsc'],
        },
        {
            title: self.$t('topBar.navigations.modules.inbound'),
            key: 'module.carrierSupplierInboundLoads',
            name: 'module.carrierSupplierInboundLoads',
            url: '/carrier/s/inbound/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['inboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            key: 'module.carrierSupplierOutboundLoads',
            name: 'module.carrierSupplierOutboundLoads',
            url: '/carrier/s/outbound/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['outboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.reexport'),
            key: 'module.carrierSupplierReexportLoads',
            name: 'module.carrierSupplierReexportLoads',
            url: '/carrier/s/reexport/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['reexportloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.dtdTransports'),
            key: 'module.carrierSupplierDealerToDealerLoads',
            name: 'module.carrierSupplierDealerToDealerLoads',
            url: '/carrier/s/dealer/to/dealer/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['ddloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.spot'),
            key: 'module.carrierSupplierSpotLoads',
            name: 'module.carrierSupplierSpotLoads',
            url: '/carrier/s/spot/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['spotloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['contactsownview', 'locationsownview', 'transportvehiclesownview', 'driversownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    hasAnyPermission: ['contactsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/locations',
                    hasAnyPermission: ['locationsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.drivers'),
                    key: 'module.drivers',
                    name: 'module.drivers',
                    url: '/drivers',
                    hasAnyPermission: [],
                    hasAllPermissions: ['transportvehiclesownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportVehicles'),
                    key: 'module.transportvehicles',
                    name: 'module.transportvehicles',
                    url: '/transport/vehicles',
                    hasAnyPermission: [],
                    hasAllPermissions: ['driversownview'],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveSupplierCarrierModule',
            name: 'archiveSupplierCarrierModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveowninboundloadsview', 'archiveownoutboundloadsview', 'archiveownreexportloadsview', 'archiveownddloadsview',
                'archiveownspotloadsview'
            ],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'archiveSupplierCarrierModule.inboundLoad',
                    name: 'archiveSupplierCarrierModule.inboundLoad',
                    url: '/s/carrier/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveowninboundloadsview'],
                    hasAllPermissions: []
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'archiveSupplierCarrierModule.outboundLoad',
                    name: 'archiveSupplierCarrierModule.outboundLoad',
                    url: '/s/carrier/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownoutboundloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'archiveSupplierCarrierModule.reexportLoad',
                    name: 'archiveSupplierCarrierModule.reexportLoad',
                    url: '/s/carrier/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownreexportloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'archiveSupplierCarrierModule.dtdLoad',
                    name: 'archiveSupplierCarrierModule.dtdLoad',
                    url: '/s/carrier/archives/dd/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownddloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'archiveSupplierCarrierModule.spotLoad',
                    name: 'archiveSupplierCarrierModule.spotLoad',
                    url: '/s/carrier/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownspotloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },


        // compound
        {
            title: self.$t('topBar.navigations.categories.compound'),
            key: 'compound',
            name: 'compound',
            category: 2,
        },

        {
            title: self.$t('topBar.navigations.modules.vehicleArrivals'),
            key: 'compoundSupplierVehicleArrivals',
            name: 'compoundSupplierVehicleArrivals',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['inboundloadsownview', 'spotloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inbound'),
                    key: 'compoundSupplierVehicleArrivals.inbound',
                    name: 'compoundSupplierVehicleArrivals.inbound',
                    url: '/compound/s/inbound/loads',
                    icon: 'fe fe-circle',
                    hasAllPermissions: ['inboundloadsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'compoundSupplierVehicleArrivals.spot',
                    name: 'compoundSupplierVehicleArrivals.spot',
                    url: '/compound/s/spot/loads',
                    icon: 'fe fe-circle',
                    hasAllPermissions: ['spotloadsownview'],
                },
            ]
        },

        // {
        //     title: self.$t('topBar.navigations.modules.vehicleArrivals'),
        //     key: 'compoundSupplier.inboundLoads',
        //     name: 'compoundSupplier.inboundLoads',
        //     url: '/compound/s/inbound/loads',
        //     icon: 'fe fe-circle',
        //     hasAnyPermission: ['inboundloadsownview'],
        //     hasAllPermissions: [],
        // },
        {
            title: self.$t('topBar.navigations.modules.compound'),
            key: 'compoundSupplierCompound',
            name: 'compoundSupplierCompound',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['ordersownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.stock'),
                    key: 'compoundSupplierCompound.stock',
                    name: 'compoundSupplierCompound.stock',
                    url: '/compound/s/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'compoundSupplierCompound.serviceOrders',
                    name: 'compoundSupplierCompound.serviceOrders',
                    url: '/compound/s/service/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'compoundSupplierCompound.damages',
                    name: 'compoundSupplierCompound.damages',
                    url: '/compound/s/damages/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            key: 'compoundOutboundLoads',
            name: 'compoundOutboundLoads',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['outboundloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.deliveryRequests'),
                    key: 'compoundOutboundLoads.deliveryRequests',
                    name: 'compoundOutboundLoads.deliveryRequests',
                    url: '/compound/s/orders/delivery/requests',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.pickUpAnnouncements'),
                    key: 'compoundOutboundLoads.pickUpAnnouncements',
                    name: 'compoundOutboundLoads.pickUpAnnouncements',
                    url: '/compound/s/outbound/loads/pick/up/announcements',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.reexport'),
            key: 'compoundReexportLoads',
            name: 'compoundReexportLoads',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['reexportloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'compoundReexportLoads.reexports',
                    name: 'compoundReexportLoads.reexports',
                    url: '/compound/s/reexport/loads',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.pickUpAnnouncements'),
                    key: 'compoundReexportLoads.pickUpAnnouncements',
                    name: 'compoundReexportLoads.pickUpAnnouncements',
                    url: '/compound/s/reexport/loads/pick/up/announcements',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ],
        },
        {
            title: self.$t('topBar.navigations.modules.webhookLogs'),
            key: 'module.compoundSupplierWebhookLogs',
            name: 'module.compoundSupplierWebhookLogs',
            url: '/compound/s/event/logs',
            icon: 'fe fe-circle',
            hasAnyPermission: ['archiveowninboundloadsview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveSupplierCompoundModule',
            name: 'archiveSupplierCompoundModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveowninboundloadsview', 'archiveownoutboundloadsview', 'archiveownreexportloadsview', 'archiveownordersview', 'archiveownspotloadsview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'archiveSupplierCompoundInboundLoad',
                    name: 'archiveSupplierCompoundInboundLoad',
                    url: '/compound/s/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAllPermissions: ['archiveowninboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'archiveSupplierCompoundModule.outboundLoad',
                    name: 'archiveSupplierCompoundModule.outboundLoad',
                    url: '/compound/s/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAllPermissions: ['archiveownoutboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'archiveSupplierCarrierModule.orders',
                    name: 'archiveSupplierCarrierModule.orders',
                    url: '/compound/s/archives/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAllPermissions: ['archiveownordersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'archiveSupplierCompoundModule.reexportLoad',
                    name: 'archiveSupplierCompoundModule.reexportLoad',
                    url: '/compound/s/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAllPermissions: ['archiveownreexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'archiveSupplierCompoundSpotLoads.spotLoad',
                    name: 'archiveSupplierCompoundSpotLoads.spotLoad',
                    url: '/compound/s/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownspotloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },
    ]
}
