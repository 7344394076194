export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fa fa-tachometer',
            url: '/dashboard/alpha',
            hasAllPermissions: []
        },
        {
            title: self.$t('topBar.navigations.categories.forecasts'),
            key: 'forecasts',
            name: 'forecasts',
            icon: 'fa fa-check-square',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            category: 1,
        },
        {
            title: self.$t('topBar.navigations.atn'),
            key: 'deliveryForecasts',
            name: 'deliveryForecasts',
            icon: 'fa fa-legal',
            hasAnyPermission: ['ordersview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.volumes'),
                    key: 'deliveryForecasts.volumes',
                    name: 'deliveryForecasts.volumes',
                    url: '/order/drafts',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.capacityConfirmation'),
                    key: 'deliveryForecasts.capacityConfirmation',
                    name: 'deliveryForecasts.capacityConfirmation',
                    url: '/order/drafts/capacity/confirmations',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.yearlyForecasts'),
            key: 'annualForecasts',
            name: 'annualForecasts',
            url: '/annual/forecasts',
            icon: 'fa fa-align-right',
            hasAnyPermission: ['annualforecastsview'],
        },

        {
            title: self.$t('topBar.navigations.categories.orderManagement'),
            key: 'oModule',
            name: 'oModule',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            category: 2,
        },
        {
            title: self.$t('topBar.navigations.inbound'),
            key: 'inboundModule',
            name: 'orderModule',
            icon: 'fa fa-legal',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'inboundModule.orders',
                    name: 'inboundModule.orders',
                    url: '/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'inboundModule.inboundloads',
                    name: 'inboundModule.inboundloads',
                    url: '/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.compound'),
            key: 'compoundModule',
            name: 'compoundModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'compoundModule.compoundOrders',
                    name: 'compoundModule.compoundOrders',
                    url: '/stocked/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'compoundModule.compoundServiceOrders',
                    name: 'compoundModule.compoundServiceOrders',
                    url: '/s/h/service/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'compoundModule.compoundDamages',
                    name: 'compoundModule.compoundDamages',
                    url: '/s/h/damages/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'compoundModule.reExport',
                    name: 'compoundModule.reExport',
                    icon: 'fa fa-dot-circle-o',
                    url: '/reexport/loads',
                    hasAnyPermission: ['reexportloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.outbound'),
            key: 'outboundModule',
            name: 'outboundModule',
            icon: 'fa fa-sun-o',
            hasAnyPermission: ['ordersview', 'outboundloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'outboundModule.outboundloads',
                    name: 'outboundModule.outboundloads',
                    url: '/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportRequests'),
                    key: 'outboundModule.transportRequests',
                    name: 'outboundModule.transportRequests',
                    url: '/orders/outbound/transport/requests',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.DTD'),
            key: 'dtdModule',
            name: 'dtdModule',
            icon: 'fa fa-sun-o',
            hasAnyPermission: ['ddloadsview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'dtdModule.transportOrders',
                    name: 'dtdModule.transportOrders',
                    url: '/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.manualOrders'),
                    key: 'dtdModule.manualOrders',
                    name: 'dtdModule.manualOrders',
                    url: '/orders/dealer/to/dealer',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportRequests'),
                    key: 'dtdModule.transportRequests',
                    name: 'dtdModule.transportRequests',
                    url: '/orders/dealer/to/dealer/transport/requests',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.spot'),
            key: 'spot',
            name: 'spot',
            icon: 'fe fe-users',
            hasAnyPermission: ['spotloadsview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.loads'),
                    key: 'spot.loads',
                    name: 'spot.loads',
                    url: '/spot/loads',
                    icon: 'fe fe-feather',
                    hasAnyPermission: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'spot.orders',
                    name: 'spot.orders',
                    url: '/spot/orders',
                    icon: 'fe fe-feather',
                    hasAnyPermission: [],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.accessManagement'),
            key: 'aModule',
            name: 'aModule',
            icon: 'fe fe-users',
            hasAnyPermission: ['usersview', 'rolesview', 'eflusersview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.users'),
                    key: 'folderUserManagement.users',
                    name: 'folderUserManagement.users',
                    url: '/users',
                    icon: 'fe fe-users',
                    hasAnyPermission: [],
                    hasAllPermissions: ['usersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.eflUsers'),
                    key: 'folderUserManagement.eflUsers',
                    name: 'folderUserManagement.eflUsers',
                    url: '/users/efl',
                    icon: 'fe fe-user-plus',
                    hasAnyPermission: [],
                    hasAllPermissions: ['eflusersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.userRoles'),
                    key: 'folderUserManagement.roles',
                    name: 'folderUserManagement.roles',
                    url: '/roles',
                    icon: 'fe fe-user-check',
                    hasAnyPermission: ['rolesview'],
                    hasAllPermissions: [],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.contractManagement'),
            key: 'cManagement',
            name: 'cManagement',
            icon: 'fe fe-package',
            hasAnyPermission: ['contractsview', 'pricesview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.contracts'),
                    key: 'module.contracts',
                    name: 'module.contracts',
                    url: '/contracts',
                    icon: 'fe fe-package',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contractsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.prices'),
                    key: 'module.prices',
                    name: 'module.prices',
                    url: '/prices',
                    icon: 'fe fe-dollar-sign',
                    hasAnyPermission: [],
                    hasAllPermissions: ['pricesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.loadVolumes'),
                    key: 'module.loadVolumes',
                    name: 'module.loadVolumes',
                    url: '/price/volumes',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['pricesview'],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'bModule',
            name: 'bModule',
            icon: 'fe fe-command',
            hasAnyPermission: ['manufacturersview', 'clientsview', 'locationcodesview', 'locationtypesview', 'locationsview', 'routesview', 'brandsview', 'modelsview', 'zonesview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.manufacturers'),
                    key: 'module.manufacturers',
                    name: 'module.manufacturers',
                    url: '/manufacturers',
                    icon: 'fe fe-command',
                    hasAnyPermission: [],
                    hasAllPermissions: ['manufacturersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    icon: 'fe fe-smartphone',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locationCodes'),
                    key: 'module.locationcodes',
                    name: 'module.locationcodes',
                    url: '/location/codes',
                    icon: 'fe fe-map',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationcodesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locationTypes'),
                    key: 'module.locationtypes',
                    name: 'module.locationtypes',
                    url: '/location/types',
                    icon: 'fe fe-navigation',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationtypesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/locations',
                    icon: 'fe fe-map-pin',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.routes'),
                    key: 'module.routes',
                    name: 'module.routes',
                    url: '/routes',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['routesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.zones'),
                    key: 'folderModule.zones',
                    name: 'folderModule.zones',
                    url: '/zones',
                    icon: 'fe fe-navigation-2',
                    hasAnyPermission: [],
                    hasAllPermissions: ['zonesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.brands'),
                    key: 'module.brands',
                    name: 'module.brands',
                    url: '/brands',
                    icon: 'fe fe-bold',
                    hasAnyPermission: [],
                    hasAllPermissions: ['brandsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.models'),
                    key: 'module.models',
                    name: 'module.models',
                    url: '/models',
                    icon: 'fe fe-compass',
                    hasAnyPermission: [],
                    hasAllPermissions: ['modelsview'],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.supplierDataManagement'),
            key: 'sModule',
            name: 'sModule',
            icon: 'fe fe-rss',
            hasAnyPermission: ['suppliersview', 'driversview', 'logistictypesview', 'transportvehiclesview', 'additionalservicesview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.suppliers'),
                    key: 'module.suppliers',
                    name: 'module.suppliers',
                    url: '/suppliers',
                    icon: 'fe fe-rss',
                    hasAnyPermission: [],
                    hasAllPermissions: ['suppliersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.drivers'),
                    key: 'module.drivers',
                    name: 'module.drivers',
                    url: '/drivers',
                    icon: 'fe fe-truck',
                    hasAnyPermission: [],
                    hasAllPermissions: ['driversview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.logisticTypes'),
                    key: 'module.logisticTypes',
                    name: 'module.logisticTypes',
                    url: '/logistic/types',
                    icon: 'fe fe-git-commit',
                    hasAnyPermission: [],
                    hasAllPermissions: ['logistictypesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportVehicles'),
                    key: 'module.transportvehicles',
                    name: 'module.transportvehicles',
                    url: '/transport/vehicles',
                    icon: 'fe fe-tv',
                    hasAnyPermission: [],
                    hasAllPermissions: ['transportvehiclesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.additionalServices'),
                    key: 'folderModule.additionalservices',
                    name: 'folderModule.additionalservices',
                    url: '/additional/services',
                    icon: 'fe fe-cpu',
                    hasAnyPermission: [],
                    hasAllPermissions: ['additionalservicesview'],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.clientDataManagement'),
            key: 'cModule',
            name: 'cModule',
            icon: 'fe fe-layers',
            hasAnyPermission: ['clientsview', 'dealersview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.clients'),
                    key: 'module.clients',
                    name: 'module.clients',
                    url: '/clients',
                    icon: 'fe fe-layers',
                    hasAnyPermission: [],
                    hasAllPermissions: ['clientsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dealers'),
                    key: 'module.dealers',
                    name: 'module.dealers',
                    url: '/dealers',
                    icon: 'fe fe-voicemail',
                    hasAnyPermission: [],
                    hasAllPermissions: ['dealersview'],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveModule',
            name: 'archiveModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveinboundloadview', 'archiveoutboundloadview', 'archiveorderview', 'archivereexportloadsview',  'archivespotloadsview', 'archiveddloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'folderArchive.inboundLoad',
                    name: 'folderArchive.inboundLoad',
                    url: '/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveinboundloadview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'folderArchive.outboundLoad',
                    name: 'folderArchive.outboundLoad',
                    url: '/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveoutboundloadview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'folderArchive.orders',
                    name: 'folderArchive.orders',
                    url: '/archives/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveorderview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'folderArchive.reexportLoads',
                    name: 'folderArchive.reexportLoads',
                    url: '/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archivereexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'folderArchive.dealerToDealerLoads',
                    name: 'folderArchive.dealerToDealerLoads',
                    url: '/archives/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveddloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'folderArchive.spotLoads',
                    name: 'folderArchive.spotLoads',
                    url: '/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archivespotloadsview'],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.modules.emailLogs'),
            key: 'emailLogs',
            name: 'emailLogs',
            url: '/email/logs',
            icon: 'fa fa-align-left',
            hasAllPermissions: ['emaillogsview'],
        },

        {
            title: self.$t('topBar.navigations.modules.autoInboundRules'),
            key: 'autoInboundRules',
            name: 'autoInboundRules',
            url: '/auto/inbound/rules',
            icon: 'fa fa-dot-circle-o',
            hasAllPermissions: ['autoinboundrulesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.autoInboundLogs'),
            key: 'autoInboundLogs',
            name: 'autoInboundLogs',
            url: '/auto/inbound/logs',
            icon: 'fa fa-dot-circle-o',
            hasAllPermissions: ['autoinboundrulesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.autoTransferClientRules'),
            key: 'autoTransferClientRules',
            name: 'autoTransferClientRules',
            url: '/auto/transfer/client/rules',
            icon: 'fa fa-dot-circle-o',
            hasAnyPermission: [],
            hasAllPermissions: ['autotransferclientrulesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.analytics'),
            key: 'analytics',
            name: 'analytics',
            url: '/analytics',
            icon: 'fa fa-area-chart',
            hasAllPermissions: ['analyticsview'],
        },
    ]
}
