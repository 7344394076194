import {getMenuData as baseMenu} from "./base.menu";

/**
 * This is the navigation for all Suppliers
 */
export function getMenuData(self) {

    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: 'ATN',
            key: 'module.carrierSupplierDeliveryForecasts',
            name: 'module.carrierSupplierDeliveryForecasts',
            url: '/carrier/s/order/drafts',
            icon: 'fe fe-circle',
            hasAnyPermission: ['orderdraftscanseechartsc', 'orderdraftscanseelistsc'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.inbound'),
            key: 'module.carrierSupplierInboundLoads',
            name: 'module.carrierSupplierInboundLoads',
            url: '/carrier/s/inbound/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['inboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            key: 'module.carrierSupplierOutboundLoads',
            name: 'module.carrierSupplierOutboundLoads',
            url: '/carrier/s/outbound/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['outboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.reexport'),
            key: 'module.carrierSupplierReexportLoads',
            name: 'module.carrierSupplierReexportLoads',
            url: '/carrier/s/reexport/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['reexportloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.dtdTransports'),
            key: 'module.carrierSupplierDealerToDealerLoads',
            name: 'module.carrierSupplierDealerToDealerLoads',
            url: '/carrier/s/dealer/to/dealer/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['ddloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.spot'),
            key: 'module.carrierSupplierSpotLoads',
            name: 'module.carrierSupplierSpotLoads',
            url: '/carrier/s/spot/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['spotloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['contactsownview', 'locationsownview', 'transportvehiclesownview', 'driversownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/locations',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.drivers'),
                    key: 'module.drivers',
                    name: 'module.drivers',
                    url: '/drivers',
                    hasAnyPermission: [],
                    hasAllPermissions: ['transportvehiclesownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportVehicles'),
                    key: 'module.transportvehicles',
                    name: 'module.transportvehicles',
                    url: '/transport/vehicles',
                    hasAnyPermission: [],
                    hasAllPermissions: ['driversownview'],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveSupplierCarrierModule',
            name: 'archiveSupplierCarrierModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveowninboundloadsview', 'archiveownoutboundloadsview', 'archiveownreexportloadsview', 'archiveownddloadsview', 'archiveownspotloadsview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'archiveSupplierCarrierModule.inboundLoad',
                    name: 'archiveSupplierCarrierModule.inboundLoad',
                    url: '/s/carrier/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveowninboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'archiveSupplierCarrierModule.outboundLoad',
                    name: 'archiveSupplierCarrierModule.outboundLoad',
                    url: '/s/carrier/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownoutboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'archiveSupplierCarrierModule.reexportLoad',
                    name: 'archiveSupplierCarrierModule.reexportLoad',
                    url: '/s/carrier/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownreexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'archiveSupplierCarrierModule.dtdLoad',
                    name: 'archiveSupplierCarrierModule.dtdLoad',
                    url: '/s/carrier/archives/dd/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownddloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'archiveSupplierCarrierModule.spotLoad',
                    name: 'archiveSupplierCarrierModule.spotLoad',
                    url: '/s/carrier/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownspotloadsview'],
                },
            ]
        },
    ]
}
