<template>
    <span>
          <b-badge v-if="status == 1" :title="comment" v-b-tooltip variant="warning">{{(label) ? label : $t('title.loadApprovalPending')}}</b-badge>
          <b-badge v-if="status == 2" :title="comment" v-b-tooltip variant="danger">{{(label) ? label : $t('title.loadApprovalRejected')}}</b-badge>
          <b-badge v-if="status == 3" :title="comment" v-b-tooltip variant="success">{{(label) ? label : $t('title.loadApprovalConfirmed')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'label', 'comment'],
        data() {
            return {}
        }
    }
</script>
