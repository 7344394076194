import localeAntd from "ant-design-vue/lib/locale-provider/en_US"

const messages = {
    topBar: {
        typeToSearch: "Search...",
        profileMenu: {
            hello: "Hello",
            email: "Email",
            phone: "Phone",
            editProfile: "Edit Profile",
            logout: "Logout",
        },
        navigations: {
            dashboards: "Dashboard",
            accessManagement: "Access Management",
            contractManagement: "Contract Management",
            supplierDataManagement: "Supplier Data Management",
            baseDataManagement: "Base Data Management",
            clientDataManagement: "Client Data Management",
            settings: "Settings",
            orderManagement: "Order Management",
            inbound: "Inbound",
            compound: "Compound",
            outbound: "Outbound",
            spot: "Spot",
            DTD: "DTD",
            atn: "ATN",
            userManagement: {
                userManagement: "User Management",
                users: "Users",
                userRoles: "User Roles",
            },
            categories: {
                forecasts: 'Forecasts',
                orderManagement: 'Order Management',
                carrier: "Carrier",
                compound: "Compound",
            },
            modules: {
                ccEmails: "CC Emails",
                outboundLoad: "Outbound Load",
                inboundLoad: "Inbound Load",
                spot: "Spot",
                profile: "Profile",
                master: "Masters",
                brands: "Brands",
                models: "Models",
                locationTypes: "Location Types",
                contacts: "Contacts",
                locations: "Locations",
                logisticTypes: "Logistic Types",
                contracts: "Contracts",
                transportVehicles: "Fleet Specification",
                drivers: "Drivers",
                routes: "Routes",
                clients: "Clients",
                prices: "Pricing",
                manufacturers: "Manufacturers",
                suppliers: "Suppliers",
                dealers: "Dealers",
                users: "Users",
                userRoles: "User Roles",
                orders: "Orders",
                inboundLoads: "Inbound Loads",
                outboundLoads: "Outbound Loads",
                carrierInboundLoads: "Carrier Inbound",
                compoundInboundLoads: "Compound Inbound",
                carrierOutboundLoads: "Carrier Outbound",
                compoundOutboundLoads: "Compound Outbound",
                additionalServices: "Additional Services",
                zones: "Outbound Delivery Zones",
                compound: "Compound",
                inbound: "Inbound",
                outbound: "Outbound",
                transportRequests: "Transport Requests",
                pickUpAnnouncements: "Pickup Announcements",
                orderDrafts: "Order Drafts",
                locationCodes: "Location Codes",
                deliveryForecasts: 'Delivery Forecasts',
                readyForPickUp: 'Ready for Pick up',
                serviceOrders: 'Service Orders',
                deliveryRequests: 'Delivery Requests',
                webhookLogs: 'WebHook Logs',
                yearlyForecasts: 'Yearly Forecasts',
                stockStatus: 'Stock Status',
                transportOrders: 'Transport Orders',
                transportForecasts: 'Transport Forecasts',
                damages: 'Damages',
                dtdTransports: 'DTD Transports',
                reexport: 'Reexport',
                eflUsers: 'EFL Users',
                archived: "Archived",
                vehicleArrivals: "Vehicle Arrivals",
                stock: "Stock",
                archive: "Archive",
                apiLogs: "Api Logs",
                emailLogs: "Email Logs",
                loads: "Loads",
                autoInboundRules: "Auto Inbound Rules",
                autoInboundLogs: "Auto Inbound Logs",
                deliveryRequest: "Delivery Request",
                analytics: "Analytics",
                spotLoad: "Spot Load",
                systemMessages: "System Messages",
                emailSettings: "Email Settings",
                autoTransferClientRules: "Auto Transfer Client Rules",
                autoTransferClientRuleLogs: "Auto Transfer Client Logs",
                volumes: "Volumes",
                capacityConfirmation: "Capacity Confirmation",
                loadVolumes: 'Load Volumes',
                manualOrders: "Manual Orders",
            },
            explores: {
                explore: "Explore",
            }
        }
    },
    paginations: {
        perPage: "Per Page",
        first: "First",
        prev: "Prev",
        next: "Next",
        last: "Last",
    },
    notifications: {
        title: {
            whoops: 'Whoops!',
            whoopsError: 'Whoops! Error!',
            whoopsWarning: 'Whoops! Warning!',
            whoopsServerError: 'Whoops! Server Error!',
            congratulations: 'Congratulations!',
            loginSuccessful: 'Login Successful!',
            loginFailed: 'Login failed!',
            sessionExpired: 'Session expired!',
            accountVerificationSuccessful: 'Account Verification Successful!',
            accountVerificationFailed: 'Account Verification Failed!',
            success: 'Success!',
            resetPasswordSuccess: 'Congratulations! Reset password successful.',
            newPasswordSet: 'New password set successful',
        },
        description: {
            welcome: 'Welcome!',
            welcomeBack: 'Welcome back!',
            pleaseTryAgain: 'Please Try Again.',
            pleaseLoginAgain: 'Please login again.',
            loginNow: 'You can login now.',
            unableToFindData: 'Unable to Find Data!',
            itemSuccessfullyAdded: 'Item Successfully Added!',
            itemSuccessfullyUpdated: 'Item Successfully Updated!',
            itemSuccessfullyDeleted: 'Item Successfully Deleted!',
            unableToFindItem: 'Unable to Find Item!',
            unableToDeleteItem: 'Unable to Delete Item!',
            otherObjectsDependOnIt: 'Cannot delete item because other objects depend on it!',
            checkInbox: 'Check inbox! We have sent you email.',
            locationDependencyMsg: 'You can not change the "City" or "Country" field because other objects depend on it!',
            routeDependencyMsg: 'You can not change the "From Location" or "To Location" field because other objects depend on it!',
            priceDependencyMsg: 'You can not change the "Route", "Brand", "Model" or "Supplier" field because other objects depend on it!',
            invalidUploadFIle: 'Not a valid upload file!',
            fileSizeTooBig: 'File Size is too big! Try Again!',
            loadsSuccessfullyCreated: 'Loads successfully created!',
            requiredFieldsMissing: 'Seems like required fields missing. Try Again',
            pleaseRefreshPage: 'Please refresh the page and try again!',
            damageAdditionalServiceExistMsg: 'Changing status to stock failed because there are active additional services or damages.',
            importSuccess: 'Import Success',
            importUpdateSuccess: 'Import Update Success',
            priceNotFound: 'Unable to decide a price',
            autoRunProcessFinished: 'Auto run finished please check logs',
            orderDraftExist: 'This price is in a middle of ATN confirmation request, please be aware that any change made here might effect that flow!',
        }
    }
}

const inputs = {
    carriers: "Carriers",
    compounds: "Compounds",
    loadingPoint: "Loading Point",
    unloadingPoint: "Unloading Point",
    drivers: "Drivers",
    vehicles: "Vehicles",
    orderTransportStatus: "Order Transport Status",
    transportRequestType: "Transport Request Type",
    manufacturers: "Manufacturers",
    name: "Name",
    fullName: "Full Name",
    email: "Email",
    password: "Password",
    role: "Role",
    phone: "Phone",
    confirmPassword: "Confirm Password",
    status: "Status",
    whatAreYouLookingFor: "What are you looking for?",
    fromAddedDate: "From Added Date",
    toAddedDate: "To Added Date",
    title: "Title",
    description: "Description",
    longDescription: "Long Description",
    allowLogin: "Allow Login",
    width: "Width(mm)",
    length: "Length(mm)",
    height: "Height(mm)",
    weight: "Weight(kg)",
    versionCode: "Version Code",
    configCode: "Config Code",
    box: "Box",
    isMandatory: "is mandatory?",
    additionalEquipment: "Additional Equipment",
    type: "Type",
    brand: "Brand",
    deliveryFactors: "Delivery Factor",
    is_active: "Is Active?",
    firstName: "First Name",
    lastName: "Last Name",
    mobile: "Mobile",
    function: "Function",
    location_type: "Location Type",
    street: "Street",
    street_no: "Street No",
    zip: "Zip",
    city: "City",
    country: "Country",
    opening_hours: "Opening Hours",
    fromOpeningHours: "From Opening Hours",
    toOpeningHours: "To Opening Hours",
    duration: "Duration",
    startDate: "Start Date",
    endDate: "End Date",
    supplier: "Supplier",
    transport_vehicles: "Transport Vehicle",
    capacity: "Capacity",
    plateNumber: "Plate Number",
    fromLocation: "From Location",
    toLocation: "To Location",
    companyName: "Company Name",
    comment: "Comment",
    fax: "Fax",
    locations: "Locations",
    dealers: "Dealers",
    contact: "Contact",
    location: "Location",
    models: "Models",
    logistic_type: "Logistic Type",
    leadTimePickup: "Lead Times: Pickup",
    leadTimeTransport: "Lead Times: Transport",
    fullLoadedPrice: "Full Loaded Price",
    singleLoadedPrice: "Single Loaded Price",
    route: "Route",
    model: "Model",
    mainLocation: "Main Location",
    additionalLocation: "Additional Location",
    locationType: "Location Type",
    supplierReleaseAgent: "Release Agent",
    supplierLogisticCompanies: "Logistic Companies",
    supplierTransportationCompanies: "Supplier Transport-Companies",
    brands: "Brands",
    logisticTypes: "Logistic Types",
    autoInboundRules: "Auto Inbound Rules",
    autoTransferClientRules: "Auto Transfer Client Rules",
    contracts: "Contracts",
    supplierTypes: "Supplier Types",
    functions: "Functions",
    dealerId: "Dealer Id",
    contacts: "Contacts",
    code: "Code",
    identificationNumber: "Identification Number",
    meansOfTransport: "Means of Transport",
    euroNorm: "Euro Norm",
    yearOfProduction: "Year Of Production",
    client: "Client",
    dealer: "Dealer",
    orderId: "Order Id",
    importerNumber: "Importer Number",
    invoiceDateImporter: "Invoice Date Importer",
    supplierDrivers: "Supplier's Drivers",
    supplierRoutes: "Supplier's Routes",
    supplierTransportVehicles: "Supplier's Transport Vehicles",
    acceptFromSupplier: "Accept From Supplier",
    loadingFactors: "Loading Factors",
    clients: "Clients",
    fromLocations: "From Locations",
    toLocations: "To Locations",
    zipFrom: "Zip From",
    zipTo: "Zip To",
    additionalServices: "Additional Services",
    additionalService: "Additional Service",
    apiSecret: "API Secret",
    transportVehicle: "Transport Vehicle",
    vehicleRegistrationPlate: "Vehicle Registration Plate",
    driver: "Driver",
    estimatedLoadingTime: "Estimated Loading Time",
    estimatedUnloadingTime: "Estimated UnLoading Time",
    supplierCarrier: "Supplier Carrier",
    supplierCompound: "Supplier Compound",
    loadStatus: "Load Status",
    destinationLocation: "Destination Location",
    searchAddress: "Search address",
    address: "Address",
    damage: "DAMAGE",
    factoryReferenceNumber: 'Factory Reference Number',
    deliveryPlaceImporter: 'Delivery Place Importer',
    manufacturerInvoiceDate: 'Manufacturer Invoice Date',
    invoiceNumberImporter: 'Invoice Number Importer',
    mpoNumber: 'MPO Number',
    orderType: 'Order Type',
    orderCode: 'Order Code',
    brandName: 'Brand Name',
    orderCountry: 'Order Country',
    modelCode: 'Model Code',
    versionType: 'Version Type',
    category: 'Category',
    fuelType: 'Fuel Type',
    vinNumber: 'VIN Number',
    dealerName: 'Dealer Name',
    dealerZone: 'Dealer Zone',
    deliveryPlaceDealer: 'Delivery Place Dealer',
    destinationCountryDealer: 'Destination Country Dealer',
    modelDescription: 'Model Description',
    orderImportErrors: 'Order Import Errors',
    expectedProductionDate: 'Expected Production Date',
    actualProductionDate: 'Actual Production Date',
    callOffDateImporter: 'Call Off Date Importer',
    reexportCallOffDateImporter: 'Reexport Call Off Date Importer',
    ddCallOffDateImporter: 'DTD Call Off Date Importer',
    destinationCountryImporter: 'Destination Country Importer',
    isDone: 'Is Done?',
    selectWeek: 'Select Week',
    countries: 'Countries',
    manufacturer: 'Manufacturer',
    doesPickUpLoadRequiredLoadApproval: 'Does pick-up load approval required?',
    emails: 'Emails',
    subject: 'Subject',
    note: 'Note',
    content: 'Content',
    clientAffected: "Client Affected",
    clientTransportRequestAcceptAt: 'Transport Request Accepted At',
    clientTransportRequest: "Transport Request",
    checkAll: "Check All",
    unCheckAll: "Un-Check All",
    unloadedAt: "Unloaded At",
    pickUpConfirmationDateTime: "Pickup Confirmation Date&Time",
    dateTime: "DateTime",
    isRejected: "Is Rejected",
    reject: "Reject",
    order: "Order",
    whenCarrierAcceptInbound: "When Carrier Accept Inbound",
    whenCarrierStartJourney: "When Carrier Start Journey",
    whenCarrierCreateInboundUnloadRequest: "Carrier Create Inbound Unload Request",
    whenClientAddsUpdatesAdditionalService: "When Client Adds/Update Additional Service",
    whenCarrierStartJourneyOutbound: "When Carrier Start Outbound Journey",
    whenClientCreateTransportRequest: "Client Create Transport Request",
    whenInboundUpdated: "When Inbound Updated",
    whenInboundDeleted: "When Inbound Deleted",
    year: "Year",
    suppliers: "Suppliers",
    priority: "Priority",
    fromEstimatedLoadingTime: "Estimated Loading Time From",
    toEstimatedLoadingTime: "Estimated Loading Time To",
    destinationCountries: "Destination Countries",
    passportNumber: "Passport Number",
    flexibility: "Capacity Flexibility (in %)",
    commitmentOnTruckLoadsPerWeek: "Contractual capacity per week (FTL)",
    outboundDelay: "Announcement AVISO outbound",
    inboundDelay: "Announcement AVISO inbound",
    priceUnit: "Price/Unit",
    priceFTL: "Price/FTL",
    currency: "Currency",
    logo: "Logo",
    truckPlateNumber: "Truck Plate Number",
    trailerPlateNumber: "Trailer Plate Number",
    secondDeliveryPlaceImporter: 'Second Delivery Place Importer',
    additionalEquipmentDescription: "Additional Equipment Desc.",
    controllerName: "Controller Name",
    from: "From",
    to: "To",
    pickup: 'Pick up',
    transit: 'Transit',
    leadTimes: 'Lead Times',
    isSupervisor: 'Is Supervisor?',
    price: 'Price',
    supervisor: 'Supervisor',
    subContractor: 'Sub-contractor',
    market: 'Market',
    spotPrice: "Spot Price",
    spotCurrency: "Spot Currency",
    showingOwnDrivers: "Showing only own drivers",
    showingOwnVehicles: "Showing only own vehicles",
    dealerLocations: "Dealer Locations",
    zone: "Zone",
    sendCredentialByEmail: "Send Credential By Email",
    dateOfAvailability: "Date Of Availability",
    selectAll: "Select All",
    whenOrderLoadStatusUpdated: "When Order Load Status Changed",
    markets: "Markets",
    fromDate: "From Date",
    toDate: "To Date",
    atnPermissions: "ATN Permission",
    priceSPL: "Price SPL",
    ddLocationCode: "DD Location Code",
    supplierCarriers: "Supplier Carriers",
    select: "Select",
    variantType: "Variant Type",
    nextClient: "Next Client",
    delayInDays: "Delay in Days",
    extraCostPerVehicle: "Extra Cost Per Vehicle",
    total: "Total",
    volume: "Volume",
    skipDealerConfirmation: 'Skip Dealer Unload Confirmation',
    dealerLocation: 'Dealer Location',
    fromZone: 'From Zone',
    toZone: 'To Zone',
    freeStorageDays: 'Free Storage Days',
    dailyStoragePrice: 'Daily Storage Price',
    handlingPrice: 'Handling Price',
    deliverTo: 'Deliver To',
}

const columns = {
    lastUpdatedAt: "Last Updated At",
    attachment: "Attachment",
    download: "Download",
    profile: "Profile",
    email: "Email",
    name: "Name",
    phone: "Phone",
    roles: "Roles",
    status: "Status",
    permission: "Permission",
    image: "Image",
    title: "Title",
    description: "Description",
    specification: "Specification",
    action: "Action",
    total: "Total",
    width: "Width(mm)",
    length: "Length(mm)",
    height: "Height(mm)",
    weight: "Weight(kg)",
    versionCode: "Version Code",
    configCode: "Config Code",
    brand: "Brand",
    type: "Type",
    deliveryFactors: "Delivery factor",
    is_active: "Is Active?",
    firstName: "First Name",
    lastName: "Last Name",
    mobile: "Mobile",
    function: "Function",
    location_type: "Location Type",
    street: "Street",
    street_no: "Street No",
    zip: "Zip",
    city: "City",
    country: "Country",
    opening_hours: "Opening Hours",
    fromOpeningHours: "From Opening Hours",
    toOpeningHours: "To Opening Hours",
    supplier: "Supplier",
    suppliers: "Suppliers",
    passportNumber: "Passport Number",
    duration: "Duration",
    start_date: "Start Date",
    end_date: "End Date",
    transport_vehicles: "Transport Vehicles",
    capacity: "Capacity",
    plateNumber: "Plate Number",
    fromLocation: "From Location",
    toLocation: "To Location",
    companyName: "Company Name",
    fax: "Fax",
    locations: "Locations",
    dealers: "Dealers",
    contact: "Contact",
    logistic_type: "Logistic Type",
    leadTimePickup: "Lead Times: Pickup",
    leadTimeTransport: "Lead Times: Transit",
    fullLoadedPrice: "Full Loaded Price",
    singleLoadedPrice: "Single Loaded Price",
    route: "Route",
    model: "Model",
    models: "Models",
    locationType: "Location Type",
    location: "Location",
    supplierReleaseAgent: "Release Agent",
    supplierLogisticCompanies: "Logistic Companies",
    supplierTransportationCompanies: "Supplier Transport-Companies",
    brands: "Brands",
    locationDetail: "Location Detail",
    logisticTypes: "Logistic Types",
    contracts: "Contracts",
    mainLocation: "Main Location",
    comment: "Comment",
    dealerId: "Dealer Id",
    code: "Code",
    identificationNumber: "Identification Number",
    supplierTypes: "Supplier Types",
    euroNorm: "Euro Norm",
    yearOfProduction: "Year Of Production",
    client: "Client",
    dealer: "Dealer",
    loadingFactors: "Loading Factors",
    transportVehicle: "Vehicle",
    loadNumber: "Load #",
    numberOfOrders: "Qty.",
    loadStatus: "Load Status",
    zipFrom: "Zip From",
    zipTo: "Zip To",
    createdAt: "Created At",
    importerNumber: "Importer Number",
    invoiceDateImporter: "Invoice Date Importer",
    orderId: "Order Id",
    orderVin: "VIN",
    driver: "Driver",
    supplierCarrier: "Carrier",
    supplierCompound: "Compound",
    destinationLocation: "Destination Location",
    clientTransportRequest: "Transport Request",
    orderType: 'Order Type',
    orderCode: 'Order Code',
    brandName: 'Brand Name',
    factoryReferenceNumber: 'Factory Reference Number',
    orderCountry: 'Order Country',
    mpoNumber: 'MPO Number',
    modelCode: 'Model Code',
    versionType: 'Version Type',
    category: 'Category',
    fuelType: 'Fuel Type',
    vinNumber: 'VIN Number',
    vin: 'VIN',
    invoiceNumberImporter: 'Invoice Number Importer',
    destinationCountryImporter: 'Destination Country Importer',
    deliveryPlaceImporter: 'Delivery Place Importer',
    dealerName: 'Dealer Name',
    dealerZone: 'Dealer Zone',
    deliveryPlaceDealer: 'Delivery Place Dealer',
    destinationCountryDealer: 'Destination Country Dealer',
    modelDescription: 'Model Description',
    orderImportErrors: 'Order Import Errors',
    expectedProductionDate: 'Expected Production Date',
    actualProductionDate: 'Actual Production Date',
    manufacturerInvoiceDate: 'Manufacturer Invoice Date',
    callOffDateImporter: 'Call Off Date Importer',
    objectType: "Object Type",
    locationCode: "Location Code",
    manufacturer: "Manufacturer",
    manufacturers: "Manufacturers",
    additionalService: 'Additional Service',
    compoundNote: "Compound Note",
    loadingPoint: "Loading Point",
    unLoadingPoint: "Unloading Point",
    ctcLoadingPoint: "CTC Loading Point",
    ctcUnLoadingPoint: "CTC Unloading Point",
    clientTransportRequestAcceptAt: 'Transport Request Accepted At',
    webhook: 'Webhook',
    webhookUrl: 'Webhook Url',
    request: 'Request',
    response: 'Response',
    year: "Year",
    approved: "Approved",
    zone: "Zone",
    flexibility: "Capacity Flexibility (in %)",
    commitmentOnTruckLoadsPerWeek: "Contractual capacity per week (FTL)",
    outboundDelay: "Announcement AVISO outbound",
    inboundDelay: "Announcement AVISO inbound",
    isMandatory: "is Mandatory",
    priceUnit: "Unit",
    priceFTL: "FTL",
    currency: "Currency",
    startDate: "Start Date",
    endDate: "End Date",
    logo: "Logo",
    brandLogo: "Brand Logo",
    markedCode: "Market Code",
    destinationCountry: "Destination Country",
    modifiedAt: "Modified At",
    loadingDate: "Loading Date",
    orderedService: "Ordered Service",
    destinationTown: "Destination Town",
    dateOfAvailability: "Date Of Availability",
    gateIn: "Gate In",
    serviceOrderDate: "Service Order Date",
    deliveryRequest: "Delivery Request",
    truckPlateNumber: "Truck Plate Number",
    trailerPlateNumber: "Trailer Plate Number",
    truckPlateNumberShort: "Truck Reg.No",
    trailerPlateNumberShort: "Trailer Reg.No",
    additionalEquipment: "Additional Equipment",
    creator: "Creator",
    controllerName: "Controller Name",
    comments: "Comments",
    weekNumber: "Week Number",
    requestSentAt: "Request Sent At",
    reminderSentAt: "Reminder Sent At",
    acceptanceType: "Acceptance Type",
    acceptedAt: "Accepted At",
    acceptedBy: "Accepted By",
    dealerLocation: "Dealer Location",
    qty: "QTY",
    isSupervisor: "Is Supervisor",
    zipRanges: "ZIP Ranges",
    fromDealer: "From Dealer",
    sincom: "Sincom",
    fuel: "Fuel",
    market: "Market",
    header: "Header",
    purifiedProperties: "Purified Properties",
    originalProperties: "Original Properties",
    spotPrice: "Spot Price",
    subContractor: 'Sub-contractor',
    sentTo: 'Sent To',
    subject: 'Subject',
    process: 'Process',
    sentAt: 'Sent At',
    url: 'URL',
    ipAddress: 'IP Address',
    responseCode: 'Response Code',
    attachments: 'Attachments',
    groupId: 'Group Id',
    fromLocations: 'From Locations',
    toLocations: 'To Locations',
    supplierCarriers: 'Supplier Carriers',
    autoInboundRule: "Auto Inbound Rule",
    password: "Password",
    zones: "Zones",
    meaning: "Meaning",
    priceSPL: "SPL",
    price: "Price",
    unLoadingPlace: "UnLoading Place",
    loadingPlace: "Loading Place",
    updatedBy: "Updated By",
    numberOfVehicles: "Number of Vehicles",
    pickUpLocation: "Pick up Location",
    startOfWeek: "Start of Week",
    endOfWeek: "End of Week",
    currentClient: "Current Client",
    nextClient: "Next Client",
    delayInDays: "Delay In Days",
    autoClientTransferRule: "AutoClientTransferRule",
    orders: "Orders",
    countries: "Countries",
    singlePickupPrice: "Single Pickup Price",
    dateAndTime: "Date and Time",
    logisticType: "Log. Type",
    sendFrom: "Send From",
    contractual: "Contractual",
    nonContractual: "Non-contractual",
    week: "Week",
    numberOfLoads: "# of Loads",
    vehicles: "Vehicles",
    extraCostPerVehicle: "Extra Cost per Vehicle",
    loadVolumes: 'Load Volumes',
    skipDealerConfirmation: 'Skip Dealer Unload Confirmation',
    sentFrom: 'Sent From',
    resendAt: 'Resend At',
    fromDate: "From Date",
    toDate: "To Date",
    fromZone: 'From Zone',
    toZone: 'To Zone',
    month: 'Month',
    totalInbound: 'Total No. Of Inbounds',
    totalOutbound: 'Total No. Of Outbounds',
    totalCost: 'Total Cost',
    totalByCarrier: 'Total By Carrier',
    totalByCountry: 'Total By Country',
    grandTotal: 'Grand Total',
    freeStorageDays: 'Free Storage Days',
    dailyStoragePrice: 'Daily Storage Price',
    handlingPrice: 'Handling Price',
    deliverTo: 'Deliver To',
}

const buttons = {
    signIn: "Sign In",
    forgotPassword: "Forgot Password?",
    addNew: "Add New",
    cancel: "Cancel",
    save: "Save",
    send: "Send",
    update: "Update",
    close: "Close",
    reset: "Reset",
    apply: "Apply",
    accept: "Accept",
    reject: "Reject",
    export: "Export",
    download: "Download",
    assignLocation: "Assign Location",
    assignContact: "Assign Contact",
    assignBrand: "Assign Brand",
    assignAdditionalService: "Assign Additional Service",
    cancelEdit: "Cancel Edit",
    createLoad: "Create Load",
    inTransit: "In Transit",
    unloaded: "UnLoaded",
    updateStatus: "Update Status",
    proceedUnload: "Process Unload",
    createInboundLoad: "Inbound Load",
    createSpotInboundLoad: "Spot Inbound Load",
    createSinglePickup: "Single Pickup",
    createSpotLoad: "Create Spot Load",
    createOutboundLoad: "Outbound Load",
    priority: "Priority",
    add: "Add",
    next: "Next",
    previous: "Previous",
    back: 'Back',
    confirmWeeks: "Confirm Weeks",
    confirmAtn: "Confirm ATN",
    fullAcceptanceWithOffer: "Full Acceptance with offer",
    fullAcceptanceWithExtraCost: "Full Acceptance w/o extra cost",
    contractualAcceptance: "Contractual Acceptance",
    resetAdditionalServices: "Reset Additional Services",
    import: "Import",
    updateDDCall: "Update DD Call",
    updateReexportCall: "Update Reexport Call",
    updateCtcCall: "Update CTC Call",
    updateCallOfDate: "Update Call of Date",
    thisWeek: "This Week",
    thisMonth: "This Month",
    thisYear: "This Year",
    confirm: "Confirm",
    capacityConfirmation: "Capacity Confirmation",
    showStatus: "Show Status",
    createDealerToDealerLoad: "Create DTD Load",
    title: {
        addNewItem: "Add New Item",
        filterRecords: "Filter Records",
        resetList: "Reset List",
        addItem: "Add Item",
        editItem: "Edit Item",
        deleteItem: "Delete Item",
        uploadProfile: "Upload Profile",
        removeProfile: "Remove Profile",
        cancel: "Cancel",
        save: "Save",
        uploadImage: "Upload Image",
        removeImage: "Remove Image",
        suspendUser: "Suspend User",
        resetFilter: "Reset Filter",
        closePanel: "Close Panel",
        saveChanges: "Save Changes",
        addMore: "Add More",
        itemDetails: "Item Details",
        activateUser: "Activate User",
        detailItem: "Detail Item",
        createLoad: "Create Load",
        acceptLoad: "Accept Transport Request",
        rejectLoad: "Reject Transport Request",
        undo: "Undo",
        inTransit: "Start Journey",
        unloaded: "Confirm Unload",
        updateStatus: "Update Status",
        update: "Update",
        confirmAndProceedUnload: "Confirm and Process Unload",
        confirmUnload: "Confirm Unload",
        requestToProceedUnload: "Requested To Process Unload",
        createInboundLoad: "Create InBound Load",
        createSpotInboundLoad: "Create Spot InBound Load",
        createOutboundLoad: "Create Outbound Load",
        gateInConfirmation: "Gate in confirmation",
        switchStatus: "Switch Status",
        confirmTransportRequest: "Confirm Transport Request",
        acceptTransportRequest: "Accept Transport Request",
        createTransportRequest: "Create Transport Request",
        manageAdditionalServices: "Manage Additional Service",
        pickupConfirmationRequired: 'A waiting manufacturer approval',
        sendEmail: 'Send Email',
        sentEmail: 'Email Sent',
        manufacturerApproval: 'Manufacturer Approval',
        loadApproval: 'Load Approval',
        sendApprovalRequest: 'Send Approval Request',
        approvalRequestSent: 'Approval Request Sent',
        clientAffected: "Client Affected",
        priority: "Priority",
        exportExcel: 'Export Excel',
        addNewOrder: "Add New Order",
        approve: 'Approve',
        disapprove: 'Disapprove',
        damageRepaired: 'Damage Repaired',
        back: 'Back',
        confirmLoad: "Confirm Load",
        waitingFcaApproval: 'Waiting for Approval',
        statusUpdate: 'Status Update',
        uploadLogo: "Upload Logo",
        removeLogo: "Remove Logo",
        ccRequest: "C2C Request",
        ccDeliveryRequestAdded: "C2C Delivery Request Added",
        confirmCcCompoundRequestAdded: "Confirm C2C Delivery",
        reset: "Reset",
        confirmWeeks: "Confirm Weeks",
        confirmAtn: "Confirm ATN",
        fullAcceptanceWithOffer: "Full Acceptance with offer",
        fullAcceptanceWithExtraCost: "Full Acceptance w/o extra cost",
        contractualAcceptance: "Contractual Acceptance",
        createReexportLoad: "Create ReExport Load",
        createDealerToDealerLoad: "Create Dealer to Dealer Load",
        deleteTransportRequest: "Delete Transport Request",
        updateDTDCall: "Update DTD Call",
        resetAdditionalServices: "By clicking this all additional services will be deleted and the order will regain the status \"Stock\" !!!",
        transportOrder: "Transport Order",
        removeUpload: "Remove Upload",
        import: "Import",
        createSpotLoad: "Create SPOT Load",
        confirmStockExit: "Confirm Stock-Exit",
        gateOutConfirmation: "GateOut Confirmation",
        transferOrder: "Transfer order",
        runCommand: "Run Auto Command",
        createSinglePickup: "Create Single Pickup",
        capacityConfirmation: "Capacity Confirmation",
        confirm: "Confirm",
        reject: "Reject",
        showStatus: "Show Status",
        resendEmail: "Resend Email",
    }
}

const mainTitles = {
    ccEmails: "Cc Emails",
    archives: "Archives",
    noAttachments: "No Attachments",
    file: "File",
    damageHistoriesAttachment: "Damage Histories Attachments",
    fileAttachment: "File Attachments",
    advanceFilters: "Advance Filters",
    users: "Users",
    eflUsers: "EFL Users",
    editUser: "Edit User",
    addUser: "Add User",
    editEflUser: "Edit EFL User",
    addEflUser: "Add EFL User",
    userRoles: "User Roles",
    editRole: "Edit Role",
    addRole: "Add Role",
    signInToYourAccount: "Sign In to your account",
    welcome: "Welcome",
    brands: "Brands",
    addBrand: "Add Brand",
    editBrand: "Edit Brand",
    models: "Models",
    addModel: "Add Model",
    editModel: "Edit Model",
    addLocationType: "Add Location Type",
    editLocationType: "Edit Location Type",
    locationTypes: "Location Types",
    addContact: "Add Contact",
    editContact: "Edit Contact",
    contacts: "Contacts",
    addLocation: "Add Location",
    editLocation: "Edit Location",
    locations: "Locations",
    addLogisticType: "Add Logistic Type",
    editLogisticType: "Edit Logistic Type",
    logisticTypes: "Logistic Types",
    contracts: "Contracts",
    addContract: "Add Contract",
    editContract: "Edit Contract",
    addTransportVehicles: "Add Transport Vehicle",
    editTransportVehicles: "Edit Transport Vehicle",
    transportVehicles: "Transport Vehicles",
    addDriver: "Add Driver",
    editDriver: "Edit Driver",
    drivers: "Drivers",
    addRoute: "Add Route",
    editRoute: "Edit Route",
    routes: "Routes",
    addClient: "Add Client",
    editClient: "Edit Client",
    detailClient: "Client Details",
    clients: "Clients",
    clientContacts: "Client Contacts",
    clientBrands: "Client Brands",
    prices: "Prices",
    editPrice: "Edit Price",
    addPrice: "Add Price",
    noDataAvailable: "No data available",
    clientLocations: "Client Locations",
    clientAdditionalLocations: "Client Additional Locations",
    manufacturers: "Manufacturers",
    editManufacturer: "Edit Manufacturer",
    addManufacturer: "Add Manufacturer",
    detailManufacturer: "Manufacturer Detail",
    manufacturerContacts: "Manufacturer Contacts",
    manufacturerBrands: "Manufacturer Brands",
    manufacturerLocations: "Manufacturer Locations",
    editSupplier: "Edit Supplier",
    addSupplier: "Add Supplier",
    detailSupplier: "Supplier Details",
    supplierAdditionalLocations: "Supplier Additional Locations",
    supplierContacts: "Supplier Contacts",
    supplierLocations: "Supplier Locations",
    suppliers: "Suppliers",
    supplierTypes: "Supplier Types",
    generalInformation: "General Information",
    userProfile: "User Profile",
    addDealer: "Add Dealer",
    editDealer: "Edit Dealer",
    dealers: "Dealers",
    dealerAdditionalLocations: "Dealer Additional Locations",
    dealerContacts: "Dealer Contacts",
    dealerBrands: "Dealer Brands",
    supplierAttachments: "Supplier Attachments",
    clientAttachments: "Client Attachments",
    manufacturerAttachments: "Manufacturer Attachments",
    priceAttachments: "Price Attachments",
    fileAttachments: "File Attachments",
    dealerAttachments: "Dealer Attachments",
    contractAttachments: "Contract Attachments",
    driverAttachments: "Driver Attachments",
    supplierContracts: "Supplier Contracts",
    order: "Order",
    orders: "Orders",
    editOrder: "Edit Order",
    addOrder: "Add Order",
    inboundLoads: "Inbound Loads",
    editLoad: "Update Load",
    createLoad: "Create Load",
    detailLoad: "Detail Load",
    pickOrderForLoad: "Pick Orders for Load",
    fromLocation: "From Location",
    toLocation: "To Location",
    brand: "Brand",
    model: "Model",
    load: "Load",
    loadingFactors: "Loading Factors",
    detailDealer: "Dealer Detail",
    additionalServices: "Additional Services",
    addAdditionalService: "Add Additional Service",
    editAdditionalService: "Edit Additional Service",
    zones: "Zones",
    addZone: "Add Zone",
    editZone: "Edit Zone",
    supplierAdditionalServices: "Supplier Additional Services",
    dashboard: "Dashboard",
    acceptLoad: "Accept Transport Request",
    rejectLoad: "Reject Transport Request",
    all: "All",
    loadReferenceNumber: "Load Reference Number",
    loadingPlace: "Loading Place",
    unLoadingPlace: "UnLoading Place",
    transportationCompany: "Transportation Company",
    status: "Status",
    loadDetails: "Load Details",
    estimatedLoadingDate: "Estimated Loading Date",
    estimatedUnLoadingDate: "Estimated UnLoading Date",
    actualLoadingDate: "Actual Loading Date",
    actualUnLoadingDate: "Actual UnLoading Date",
    loadingTimeTransition: "Loading Time Transition",
    leadTimeLoad: "Lead time load",
    timeline: "Timeline",
    creationDate: "Creation Date",
    acceptedByTransportationCompany: "Accepted by transportation company",
    expectedUnloadDate: "Expected Unload date",
    compoundStockEntryDate: "Compound Stock entry date",
    compoundStockLeftFate: "Compound stock left date",
    history: "History",
    attachments: "Attachments",
    title: "Title",
    dateTime: "Date and Time",
    updated: "Updated",
    updatedBy: "Updated By",
    comments: "Comments",

    // Status of Load and Order do not change without Asking Gautam
    loadApprovalPending: "Load Approval Pending",
    loadApprovalRejected: "Load Approval Rejected",
    loadApprovalConfirmed: "Load Approval Confirmed",
    produced: "Produced",
    created: "Created",
    announced: "Announced",
    rejected: "Rejected",
    confirmed: "Confirmed",
    loaded: "Loaded",
    inTransit: "In transit",
    unloadRequested: "Unload Requested",
    unloadedCompound: "Unloaded (Compound)",
    pending: "Pending",
    addedToLoad: "Added to load",
    assignedToLoad: "Assigned to load",
    unloaded: "Unloaded",
    stocked: "Stocked",
    hold: "Hold",
    transportOrder: "Transport order",
    outboundLoadCreated: "Outbound Load Created",
    pickUp: "Pick up",
    deliveryRequested: "Delivery Requested",
    stockExitInTransit: "Stock exit - In Transit",
    delivered: "Delivered",
    loadInTransit: "Load - In Transit",
    loadUnloaded: "Load - UnLoaded",
    inboundLoadAttachments: "Inbound Load Attachments",
    ctcInboundLoadAttachments: "Compound Inbound Load Attachments",
    outboundLoadAttachments: "Outbound Load Attachments",
    compoundCompany: "Compound Company",
    proceedUnload: "Process Unload",
    supplierCompound: "Supplier Compound",
    createInboundLoad: "Create Inbound Load",
    createOutboundLoad: "Create Outbound Load",
    client: "Client",
    orderAddedToInboundLoad: "Order Added to Inbound Load",
    orderAddedToOutboundLoad: "Order Added to Outbound Load",
    outboundLoads: "Outbound Loads",
    importerNumber: "Importer Number",
    invoiceDateImporter: "Invoice Date Importer",
    inboundLoad: "Inbound Load",
    supplierCarrier: "Supplier Carrier",
    driver: "Driver",
    transportVehicle: "Transport Vehicle",
    outboundLoad: "Outbound Load",
    destinationLocation: "Destination Location",
    inboundSupplierCarrier: "Inbound Carrier Supplier",
    inboundSupplierCompound: "Inbound Compound Supplier",
    outboundSupplierCarrier: "Outbound Carrier Supplier",
    dealer: "Dealer",
    signIn: "Sign In",
    forgotPassword: "Forgot Password?",
    resetPassword: "Reset Password",
    gateInConfirmation: "Gate in confirmation",
    manufacturerAdditionalLocations: "Manufacturer Additional Locations",
    switchStatus: "Switch Status",
    locationCode: "Location Code",
    transportRequests: "Transport Requests",
    confirmTransportRequest: "Confirm Transport Request",
    acceptTransportRequest: "Accept Transport Request",
    createTransportRequest: "Create Transport Request",
    pickUpAnnouncements: "Pickup Announcements",
    transportRequestCreated: "Transport Request Created",
    transportRequestAccepted: "Transport Request Accepted",
    transportRequestAt: "Transport Request At",
    transportRequestAcceptedAt: "Transport Request Accepted At",
    inboundAdded: "Inbound Added",
    outboundAdded: "Outbound Added",
    orderDrafts: "Order Drafts",
    imported: "Imported",
    forACustomer: "For Customer",
    forAStock: "For Stock",
    orderType: 'Order Type',
    orderCode: 'Order Code',
    brandName: 'Brand Name',
    factoryReferenceNumber: 'Factory Reference Number',
    orderCountry: 'Order Country',
    mpoNumber: 'MPO Number',
    modelCode: 'Model Code',
    versionType: 'Version Type',
    category: 'Category',
    fuelType: 'Fuel Type',
    vinNumber: 'VIN Number',
    invoiceNumberImporter: 'Invoice Number Importer',
    destinationCountryImporter: 'Destination Country Importer',
    deliveryPlaceImporter: 'Delivery Place Importer',
    dealerId: 'Dealer ID',
    dealerName: 'Dealer Name',
    driverName: 'Driver Name',
    passportNumber: 'Passport Number',
    dealerZone: 'Dealer Zone',
    deliveryPlaceDealer: 'Delivery Place Dealer',
    destinationCountryDealer: 'Destination Country Dealer',
    modelDescription: 'Model Description',
    orderImportErrors: 'Order Import Errors',
    expectedProductionDate: 'Expected Production Date',
    actualProductionDate: 'Actual Production Date',
    manufacturerInvoiceDate: 'Manufacturer Invoice Date',
    callOffDateImporter: 'Call Off Date Importer',
    lastUpdatedAt: 'Last Updated At',
    qualityHoldBlocked: 'Quality hold = blocked',
    confirmationOrder: 'Confirmation Order',
    editOrderDraft: 'Edit Order Draft',
    eta: "ETA",
    locationCodes: "Location Codes",
    addOrderDraft: 'Add Order Draft',
    deliveryForecast: 'Delivery Forecast',
    manageAdditionalServices: "Manage Additional Service",
    additionalService: "Additional Service",
    description: "Description",
    compoundNote: "Compound Note",
    markAsADone: "Mark as a Done",
    done: "Done",
    list: "List",
    chart: "Chart",
    rowLabels: 'Row Labels',
    additionalLocations: "Additional Locations",
    day: "Day",
    days: "Days",
    callOff: "Call off",
    callOffAccepted: "Call off accepted",
    inbound: "Inbound",
    outbound: "Outbound",
    compound: "Compound",
    doesPickUpLoadRequiredLoadApproval: 'Additional pickup announcement required?',
    manufacturer: 'Manufacturer',
    pickupConfirmationRequired: 'Pickup Confirmation Required',
    loadConfirmEmail: 'Load Confirm Email',
    manufacturerApproval: 'Manufacturer Approval',
    loadApproval: 'Load Approval',
    accepted: 'Accepted',
    addPriority: "Add Priority",
    clientAffected: "Client Affected",
    exportExcel: 'Export Excel',
    orderId: 'OrderId',
    clientTransportRequestAcceptAt: 'Transport Request Accepted At',
    clientTransportRequest: "Transport Request",
    leadTimePickup: "Lead Times: Pickup",
    leadTimeTransport: "Lead Times: Transit",
    transitLeadTime: "Transit Lead Time",
    pickupLeadTime: "Pickup Lead Time",
    calculated: "Calculated",
    deliveryRequests: "Delivery Requests",
    deliveryRequest: "Delivery Request",
    readyToBeLoaded: "Ready to be loaded",
    pickUpConfirmationDateTime: "Pickup Confirmation Date&Time",
    existingOrders: "Existing orders",
    newOrders: "New orders",
    announce: "Announce",
    webHooks: "WebHooks",
    webhookEventLogs: "WebHook Event Logs",
    high: "High",
    medium: "Medium",
    low: "Low",
    acceptEarlyTransportOrder: "Accept Early Transport Order",
    earlyTransportOrder: "Early Transport Order",
    yearlyForecasts: 'Yearly Forecasts',
    addYearlyForecast: 'Add Yearly Forecast',
    editYearlyForecast: 'Edit Yearly Forecast',
    detailYearlyForecast: 'Detail Yearly Forecast',
    damaged: 'Damaged',
    versions: 'Versions',
    damageRepaired: "Damage Repaired",
    vinNumbers: "VIN Numbers",
    zone: "Zone",
    archivedOrders: "Archived Orders",
    waitingFcaApproval: 'Waiting for Approval',
    stock: "Stock",
    serviceOrders: 'Service Orders',
    damages: 'Damages',
    vehicleArrivals: 'Vehicle Arrivals',
    updateStockStatus: 'Update Stock Status',
    editInbound: 'Edit Inbound',
    pricePeriods: 'Pricing Periods',
    other: "Other",
    secondDeliveryPlaceImporter: 'Second Delivery Place Importer',
    compoundToCompoundLocation: 'Compound To Compound Location',
    createCtcLoad: 'Create C2C Inbound Load',
    compoundToCompoundDeliveryCtcLoad: 'Compound To Compound Delivery Request',
    comfirmCcRequest: 'Confirm Compound To Compound Delivery Request',
    updateServiceOrders: 'Update Service Orders',
    damageHistory: 'Damage History',
    confirmWeeks: "Confirm Weeks",
    confirmAtn: "Confirm ATN",
    weeklyForecastConfirmationRequestHistory: "Weekly Forecast Confirmation History",
    requested: "Request",
    unload: "Unload",
    dealerLocation: "Dealer Location",
    reexport: "ReExport",
    createReexportLoad: "Create ReExport Load",
    reexportOrders: "ReExport Orders",
    confirmUnload: "Confirm Unload",
    ltlApproval: "LTL Approval",
    price: "Price",
    zipRanges: "ZIP Ranges",
    carrier: "Carrier",
    showOnlyStockOrders: "Only Stocked",
    additionalInformation: "Additional Information",
    atnConfirmationPending: "ATN confirmation pending",
    atnReminderSent: "ATN reminder sent",
    atnFullyConfirmed: "ATN fully confirmed",
    atnPartiallyAccepted: "ATN accepted",
    atnFullyAcceptedWithOffer: "ATN fully accepted with offer",
    atnRejected: "ATN rejected",
    createDealerToDealerLoad: "Create Dealer To Dealer Load",
    dealerToDealerOrders: "Dealer To Dealer Orders",
    fromDealer: "From Dealer",
    updateDTD: "Update DTD",
    dealerToDealer: "Dealer to Dealer",
    reexportCallOffDateImporter: "Reexport Call off date importer",
    ddCallOffDateImporter: "DTD Call off date importer",
    dealerToDealerLoads: "Dealer to Dealer Loads",
    dealerToDealerLoad: "Dealer to Dealer Load",
    downloadExcel: "Download Excel",
    ccInboundLoad: "CC Inbound Load",
    confirm: "Confirm",
    importCSV: "Import CSV",
    downloadSample: "Download Sample",
    apiLogs: "Api Logs",
    detailApiLog: "Detail Api Log",
    route: 'Route',
    createSpotInboundLoad: "Create Spot InBound Load",
    spotPrice: "Spot Price",
    addSpotLoad: "Add Spot Load",
    editSpotLoad: "Edit Spot Load",
    spotLoads: "Spot Loads",
    spotLoad: "Spot Load",
    createSpotLoad: "Create Spot Load",
    reexportLoads: "Reexport Loads",
    default: "Default",
    pickupAnnouncement: "Pickup Announcement",
    gateOutConfirmation: "Gate-Out Confirmation",
    loadingPoint: "Loading Point",
    unLoadingPoint: "Unloading Point",
    loadNumber: "Load #",
    modifiedAt: "Modified At",
    loadingDate: "Loading Date",
    qty: "Qty",
    supplier: "Supplier",
    duration: "Duration",
    startDate: "Start Date",
    endDate: "End Date",
    unitPrice: "Price/Unit",
    priceFTL: "Price/FTL",
    markets: "Markets",
    flexibility: "Capacity Flexibility (in %)",
    commitmentOnTruckLoadsPerWeek: "Contractual capacity per week (FTL)",
    outboundDelay: "Announcement AVISO outbound",
    inboundDelay: "Announcement AVISO inbound",
    priceDetail: "Price Detail",
    locationDetail: "Location Detail",
    code: "Code",
    street: "Street",
    street_no: "Street No",
    zip: "Zip",
    city: "City",
    country: "Country",
    opening_hours: "Opening Hours",
    fromOpeningHours: "From Opening Hours",
    toOpeningHours: "To Opening Hours",
    isMandatory: "is mandatory?",
    additionalEquipment: "Additional Equipment",
    width: "Width(mm)",
    length: "Length(mm)",
    height: "Height(mm)",
    weight: "Weight(kg)",
    configCode: "Config Code",
    deliveryFactors: "Delivery Factors",
    truckPlateNumber: "Truck Plate Number",
    trailerPlateNumber: "Trailer Plate Number",
    transportVehicleDetail: "Transport Vehicle Detail",
    euroNorm: "Euro Norm",
    yearOfProduction: "Year Of Production",
    capacity: "Capacity",
    type: "Type",
    additionalServiceDetail: "Additional Service Detail",
    updateDDCallDateImporter: "Update DD Call Off Date Importer",
    updateReexportCallDateImporter: "Update Reexport Call Off Date Importer",
    updateCtcCallDateImporter: "Update CTC Call Off Date Importer",
    updateCallOffDateImporter: "Update Call Off Date Importer",
    transferOrderToOtherClient: "Transfer Order To Other Client",
    emailLogs: "Email Logs",
    detailEmailLog: "Detail Email Log",
    content: "Content",
    emailDetails: "Email Details",
    spotOrders: "Spot Orders",
    autoInboundRules: "Auto Inbound Rules",
    autoGeneratedInboundLogs: "Auto Generated Inbound Logs",
    market: "Market",
    dateOfAvailability: "Date of Availability",
    columns: "Columns",
    reenterPassword: "Reenter Password",
    outboundZone: "Outbound Zone",
    destinationTown: "Destination Town",
    loadStatusLegend: "Load Status Legend",
    analytics: "Analytics",
    monthlyInboundLoadsTotalBySupplier: "Monthly inbound loads total by supplier",
    month: "Month",
    data: "Data",
    total: "Total",
    monthlyOutboundLoadsTotalBySupplier: "Monthly Outbound loads total by supplier",
    monthlyReexportLoadsTotalBySupplier: "Monthly Reexport loads total by supplier",
    monthlySpotLoadsTotalBySupplier: "Monthly Spot loads total by supplier",
    monthlyDealerToDealerLoadsTotalBySupplier: "Monthly Dealer To Dealer loads total by supplier",
    currency: "Currency",
    canSeeChart: "Can see chart",
    canSeeList: "Can see list",
    canConfirmWeek: "Can confirm week",
    createSinglePickup: "Create Single Pickup",
    priceExpired: "Price Expired",
    priceExpiresSoon: "Price Will Expires Soon",
    ddLocationCode: "DD Location Code",
    supervisorApproval: "SuperVisor Approval",
    sentForApprovalToTheSupervisor: "Sent for approval to the supervisor",
    systemMessages: "System Messages",
    addSystemMessages: "System Messages",
    editSystemMessages: "System Messages",
    role: "Role",
    user: "User",
    warning: "Warning",
    info: "Info",
    danger: "Danger",
    success: "Success",
    emailSettings: "Email Settings",
    inboundSupplierCarrierETOApprovedRequestMailByManufacturer: 'Early Transportation Order(By Manufacturer)',
    inboundSupplierCarrierApprovalRequestMail: 'Transportation Order',
    inboundSupplierCarrierETOApprovalRequestMail: 'Early Transportation Order',
    inboundSupplierCarrierAcceptEarlyInboundLoadRequest: 'Early Transport order ACCEPTED',
    inboundSupplierCarrierAcceptInboundLoadRequest: 'Transport order ACCEPTED',
    inboundSupplierCarrierDeclineEarlyInboundLoadRequest: 'Early Transport order Declined',
    inboundSupplierCarrierDeclineInboundLoadRequest: 'Transport order Declined',
    inboundSupplierCarrierETOResetInboundLoadRequest: 'Early transport order has been Reset by Carrier',
    inboundSupplierCarrierResetInboundLoadRequest: 'Transport order has been Reset by Carrier',
    inboundSupplierCarrierConfirmUpdateInboundLoadRequestMail: 'Carrier {supplierCarrierName} Transport load has been confirmed',
    inboundSupplierCarrierUpdateInboundLoadRequestMail: 'Carrier {supplierCarrierName} Transport load details have been changed',
    inboundSupplierCarrierETOCancelledInboundLoadRequest: 'Cancellation of ETO for Load#{loadNumber}',
    inboundSupplierCarrierCancelledInboundLoadRequest: 'Cancellation of Load#{loadNumber}',
    inboundSupplierCarrierETOUpdateInboundLoadMail: 'Change in Early announcement for Load#{loadNumber}',
    inboundSupplierCarrierUpdateInboundLoadMail: 'Change in announcement for Load#{loadNumber}',
    inboundSupplierCompoundJourneyStartMail: 'Announcement of vehicle arrival [Journey started - {estimatedTimeArrival}]',
    numberOfVehicles: "Number of Vehicles",
    pickUpLocation: "Pick up Location",
    startOfWeek: "Start of Week",
    endOfWeek: "End of Week",
    weekNumber: "Week Number",
    versionCode: "Version Code",
    addTransferClientRule: "Add Transfer Client Rule",
    editTransferClientRule: "Edit Transfer Client Rule",
    autoTransferClientRules: "Auto Transfer Client Rules",
    autoTransferClientLogs: "Auto Transfer Client Logs",
    singlePickupPrice: "Single Pickup Price",
    singlePickup: "Single Pickup",
    ltlApprovalComment: "LTL Approval Comment",
    manufacturerApprovalComment: "Manufacturer Approval Comment",
    date: "Date",
    createdBy: "Created By",
    updateStatus: "Update Status",
    capacityConfirmation: "Capacity Confirmation",
    waitingForResponse: "Waiting for Response",
    approved: "Approved",
    eflApprovalPending: "EFL Approval Pending",
    capacityConfirmationDetail: "Capacity Confirmation Detail",
    eflStatus: "EFL Status",
    extraCost: "Extra Cost",
    requestSentAt: "Request Sent At",
    reminderSentAt: "Reminder Sent At",
    acceptanceType: "Acceptance Type",
    requestSent: "Request Sent",
    reminderSent: "Reminder Sent",
    eflApproved: "EFL Approved",
    eflRejected: "EFL Rejected",
    loadVolumes: 'Load Volumes',
    addLoadVolume: 'Add Load Volume',
    editLoadVolume: 'Edit Load Volume',
    assignedLoad: 'Assigned Load',
    currentVolume: 'Current volume',
    maxVolume: 'Max volume',
    skipDealerConfirmation: 'Skip Dealer Unload Confirmation',
    addAutoInboundRule: "Add Auto Inbound Rule",
    editAutoInboundRule: "Edit Auto Inbound Rule",
    averageDeliveryByCountry: "Average Delivery By Country",
    locationToLocation: "Location To Location",
    locationToZone: "Location To Zone",
    zoneToZone: "Zone To Zone",
    outboundSupplierCarrierResetOutboundLoadRequest: 'Outbound Transport order has been Reset by Carrier',
    outboundSupplierCarrierAcceptOutboundLoadRequest: 'Transport order Declined',
    outboundSupplierCarrierDeclineOutboundLoadRequest: 'Transport order Accepted',
    outboundSupplierCarrierOutboundApprovalRequestMail: 'Transportation Order',
    ddSupplierCarrierDealerToDealerLoadAnnouncementRequestMail: 'Dealer To Dealer Load announcement',
    ddSupplierCarrierAcceptDealerToDealerLoadRequestMail: 'Dealer To Dealer Load Request Accepted',
    ddSupplierCarrierDeclineDealerToDealerLoadRequestMail: 'Dealer To Dealer Load Request Declined',
    ddSupplierCarrierResetDealerToDealerLoadRequest: 'Dealer to Dealer Request Load has been Reset by Carrier',
    storageServices: 'Storage Services',
    deliverToDealer: 'Deliver To Dealer',
    deliverTo: 'Deliver To',
    deliverToCompound: 'Deliver To Compound',
}

const miscellaneous = {
    brand: "Brand",
    model: "Model",
    yes: "Yes",
    no: "No",
    de_active: "De-Active",
    active: "Active",
    actionWillLogOutYou: "Alert! Action will logout you",
    browseText: "Browse",
    removeUpload: "Remove Upload",
    uploadFile: "Upload File",
    uploadedFile: "File Uploaded",
    loadsWillBeCreated: "Load/s will be created",
    additionalSupervisorApprovalNeeded: "Additional supervisor approval needed for LTL",
    washing: "Washing",
    storageMaintenance: "Storage maintenance",
    addingKIT: "Adding KITs, items",
    other: "Other",
    welcome: "Welcome",
    to: "to",
    requiredFieldsMissing: 'Required fields are missing or invalid',
    startTypingAddress: "Start typing address...",
    systemWillAddExcelAttachment: 'System will add Excel attachment',
    thisLoadWillNeedAdditionalApprovalByManufacturer: 'This load will need additional approval by manufacturer.',
    waitingApproval: 'Waiting Approval',
    rejected: "Rejected",
    approved: "Approved",
    noRequestForTheFollowingWeeks: "No request for the following weeks!",
    unableToDecidePrice: 'Unable to decide Price',
    etoIsSentViaPlatformToCarrier: "ETO is sent via platform to carrier",
    carriersAnnouncementNeedsToBeValidated: "Carrier's announcement needs to be validated",
    theTransportAnnouncementIsValidatedEarlyTransportOrderBecomesTransportOrder: "The transport announcement is validated, Early transport order becomes Transport Order",
    finalTransportOrderIsVerifiedByCarrier: "Final transport order is verified by carrier",
    theLoadIsPickUpAndItIsOnTheWayToItsDeliveryDestination: "The load is pick up and it is on the way to it's delivery destination",
    carriersInformationThatTheTruckHasUnloadedAtTheDestinationCompoundStillWaitingForCompoundToConfirmTheReceiptOfTheLoad: "Carrier's  confirmation that the truck has unloaded at the destination compound. Still waiting for compound to confirm receipt of the load",
    compoundConfirmedThatTheTruckHasBeenUnloadedAtCompound: "Compound confirmed that the truck has been unloaded at compound",
    requestAccepted: "Delivery request has been accepted",
    linkHasBeenExpired: "Whoops! Link has been expired",
    pleaseNoteThatTheVolumesAreIndicativeAndNonBinding: "Please note that the volumes are indicative and non-binding",
    weAreStillInTheTestPhaseAndTheProcessWillContinueToBeDoneManuallyUntilTheEndOfTheTestPhase: "We are still in the test phase and the process will continue to be done manually (parallel) until the end of the test phase.",
    deliveryRequestedForThisOrder: "Delivery requested for the order ",
};

const validations = {
    required: 'The field is required.',
    duplicate: 'Duplicate item not allowed.',
    min3: 'The field required minimum 3 characters.',
    required_model: 'The field required minimum 3 characters.',
    loadingFactorInvalid: 'Invalid Loading Factors.',
    invalidPrice: 'Invalid Price.',
    invalidPricePeriod: 'Invalid Price Period.',
    orderLimitExceed: 'Limit exceed.',
    minimumOrderRequired: 'Minimum 1 order required to create/update load',
    pleaseFixTheIssueBelow: 'Please fix the issue below',
    // specificationName: "The field name is required with Minimum 2 Chars",
};

const months = {
    january: 'January',
    januaryShort: 'Jan',
    february: 'February',
    februaryShort: 'Feb',
    march: 'March',
    marchShort: 'Mar',
    april: 'April',
    aprilShort: 'Apr',
    may: 'May',
    june: 'June',
    juneShort: 'Jun',
    july: 'July',
    julyShort: 'Jul',
    august: 'August',
    augustShort: 'Aug',
    september: 'September',
    septemberShort: 'Sept',
    october: 'October',
    octoberShort: 'Oct',
    november: 'November',
    novemberShort: 'Nov',
    december: 'December',
    decemberShort: 'Dec',
}

export default {
    locale: "en-US",
    localeAntd,
    messages: {
        ...messages,
        input: inputs,
        column: columns,
        button: buttons,
        title: mainTitles,
        validation: validations,
        msc: miscellaneous,
        month: months,
    },
}
