import {getMenuData as baseMenu} from "./base.menu";

/**
 * This is the navigation for all Suppliers
 */
export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: self.$t('topBar.navigations.modules.vehicleArrivals'),
            key: 'compoundSupplierVehicleArrivals',
            name: 'compoundSupplierVehicleArrivals',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['inboundloadsownview', 'spotloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inbound'),
                    key: 'compoundSupplierVehicleArrivals.inbound',
                    name: 'compoundSupplierVehicleArrivals.inbound',
                    url: '/compound/s/inbound/loads',
                    icon: 'fe fe-circle',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'compoundSupplierVehicleArrivals.spot',
                    name: 'compoundSupplierVehicleArrivals.spot',
                    url: '/compound/s/spot/loads',
                    icon: 'fe fe-circle',
                    hasAnyPermission: ['spotloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.compound'),
            key: 'compoundSupplierCompound',
            name: 'compoundSupplierCompound',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['ordersownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.stock'),
                    key: 'compoundSupplierCompound.stock',
                    name: 'compoundSupplierCompound.stock',
                    url: '/compound/s/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'compoundSupplierCompound.serviceOrders',
                    name: 'compoundSupplierCompound.serviceOrders',
                    url: '/compound/s/service/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'compoundSupplierCompound.damages',
                    name: 'compoundSupplierCompound.damages',
                    url: '/compound/s/damages/orders',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            key: 'compoundOutboundLoads',
            name: 'compoundOutboundLoads',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['outboundloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.deliveryRequests'),
                    key: 'compoundOutboundLoads.deliveryRequests',
                    name: 'compoundOutboundLoads.deliveryRequests',
                    url: '/compound/s/orders/delivery/requests',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.pickUpAnnouncements'),
                    key: 'compoundOutboundLoads.pickUpAnnouncements',
                    name: 'compoundOutboundLoads.pickUpAnnouncements',
                    url: '/compound/s/outbound/loads/pick/up/announcements',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.reexport'),
            key: 'compoundReexportLoads',
            name: 'compoundReexportLoads',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['reexportloadsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'compoundReexportLoads.reexports',
                    name: 'compoundReexportLoads.reexports',
                    url: '/compound/s/reexport/loads',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.pickUpAnnouncements'),
                    key: 'compoundReexportLoads.pickUpAnnouncements',
                    name: 'compoundReexportLoads.pickUpAnnouncements',
                    url: '/compound/s/reexport/loads/pick/up/announcements',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: [],
                },
            ],
        },
        {
            title: self.$t('topBar.navigations.modules.webhookLogs'),
            key: 'module.compoundSupplierWebhookLogs',
            name: 'module.compoundSupplierWebhookLogs',
            url: '/compound/s/event/logs',
            icon: 'fe fe-circle',
            hasAnyPermission: ['archiveowninboundloadsview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['contactsownview', 'locationsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/locations',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationsownview'],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveSupplierCompoundModule',
            name: 'archiveSupplierCompoundModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveowninboundloadsview', 'archiveownoutboundloadsview', 'archiveownreexportloadsview', 'archiveownddloadsview', 'archiveownspotloadsview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'archiveSupplierCompoundInboundLoad',
                    name: 'archiveSupplierCompoundInboundLoad',
                    url: '/compound/s/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveowninboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'archiveSupplierCompoundModule.outboundLoad',
                    name: 'archiveSupplierCompoundModule.outboundLoad',
                    url: '/compound/s/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownoutboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'archiveSupplierCarrierModule.orders',
                    name: 'archiveSupplierCarrierModule.orders',
                    url: '/compound/s/archives/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['archiveownordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'archiveSupplierCompoundModule.reexportLoad',
                    name: 'archiveSupplierCompoundModule.reexportLoad',
                    url: '/compound/s/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownreexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'archiveSupplierCompoundSpotLoads.spotLoad',
                    name: 'archiveSupplierCompoundSpotLoads.spotLoad',
                    url: '/compound/s/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownspotloadsview'],
                },
            ]
        },
    ]
}
