import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import {getAuthUser, hasAuthUser, logout, removeStorage} from "./util/Utils";

Vue.use(Router)

const router = new Router({
    // base: process.env.BASE_URL,
    base: 'admin',
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            redirect: 'dashboard/alpha',
            component: MainLayout,
            meta: {
                authRequired: true,
                hidden: true,
            },
            children: [
                // Dashboards
                {
                    path: '/dashboard/alpha',
                    name: 'Dashboard',
                    meta: {},
                    component: () => import('./views/dashboard'),
                },
                {
                    path: '/profile',
                    name: 'Profile',
                    meta: {},
                    component: () => import('./views/profiles'),
                },
                {
                    path: '/users',
                    name: 'folderUserManagement.users',
                    meta: {},
                    component: () => import('./views/folderUserManagement/users'),
                },
                {
                    path: '/users/efl',
                    name: 'folderUserManagement.eflUsers',
                    meta: {},
                    component: () => import('./views/folderUserManagement/eflUsers'),
                },
                {
                    path: '/roles',
                    name: 'folderUserManagement.roles',
                    meta: {},
                    component: () => import('./views/folderUserManagement/roles'),
                },
                {
                    path: '/brands',
                    name: 'module.brands',
                    meta: {},
                    component: () => import('./views/brands'),
                },
                {
                    path: '/models',
                    name: 'module.models',
                    meta: {},
                    component: () => import('./views/models'),
                },
                {
                    path: '/location/types',
                    name: 'module.locationTypes',
                    meta: {},
                    component: () => import('./views/locationTypes'),
                },
                {
                    path: '/location/codes',
                    name: 'module.locationCodes',
                    meta: {},
                    component: () => import('./views/locationCodes'),
                },
                {
                    path: '/contacts',
                    name: 'module.contacts',
                    meta: {},
                    component: () => import('./views/contacts'),
                },
                {
                    path: '/locations',
                    name: 'module.locations',
                    meta: {},
                    component: () => import('./views/locations'),
                },
                {
                    path: '/logistic/types',
                    name: 'module.logisticTypes',
                    meta: {},
                    component: () => import('./views/logisticTypes'),
                },
                {
                    path: '/contracts',
                    name: 'module.contracts',
                    meta: {},
                    component: () => import('./views/contracts'),
                },
                {
                    path: '/transport/vehicles',
                    name: 'module.transportVehicles',
                    meta: {},
                    component: () => import('./views/transportVehicles'),
                },
                {
                    path: '/drivers',
                    name: 'module.drivers',
                    meta: {},
                    component: () => import('./views/drivers'),
                },
                {
                    path: '/routes',
                    name: 'module.routes',
                    meta: {},
                    component: () => import('./views/routes'),
                },
                {
                    path: '/clients',
                    name: 'module.clients',
                    meta: {},
                    component: () => import('./views/clients'),
                },
                {
                    path: '/prices',
                    name: 'module.prices',
                    meta: {},
                    component: () => import('./views/prices'),
                },
                {
                    path: '/manufacturers',
                    name: 'module.manufacturers',
                    meta: {},
                    component: () => import('./views/manufacturers'),
                },
                {
                    path: '/suppliers',
                    name: 'module.suppliers',
                    meta: {},
                    component: () => import('./views/suppliers'),
                },
                {
                    path: '/dealers',
                    name: 'module.dealers',
                    meta: {},
                    component: () => import('./views/dealers'),
                },
                {
                    path: '/orders',
                    name: 'module.orders',
                    meta: {},
                    component: () => import('./views/orders'),
                },
                {
                    path: '/inbound/loads',
                    name: 'module.inboundloads',
                    meta: {},
                    component: () => import('./views/inboundLoads'),
                },
                {
                    path: '/carrier/s/inbound/loads',
                    name: 'module.carrierSupplierInboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/carrierSuppliers'),
                },
                {
                    path: '/compound/s/inbound/loads',
                    name: 'module.compoundSupplierInboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/compoundSuppliers'),
                },
                {
                    path: '/compound/s/spot/loads',
                    name: 'module.compoundSupplierSpotLoads',
                    meta: {},
                    component: () => import('./views/spotLoads/compoundSuppliers'),
                },
                {
                    path: '/d/inbound/loads',
                    name: 'module.driverInboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/drivers'),
                },
                {
                    path: '/c/inbound/loads',
                    name: 'client.inboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/clients'),
                },
                {
                    path: '/outbound/loads',
                    name: 'module.outboundloads',
                    meta: {},
                    component: () => import('./views/outboundLoads'),
                },
                {
                    path: '/carrier/s/outbound/loads',
                    name: 'module.carrierSupplierOutboundLoads',
                    meta: {},
                    component: () => import('./views/outboundLoads/carrierSuppliers'),
                },
                {
                    path: '/compound/s/orders/delivery/requests',
                    name: 'compoundOutboundLoads.deliveryRequests',
                    meta: {},
                    component: () => import('./views/orders/compoundSuppliers/transportRequests'),
                },
                {
                    path: '/compound/s/outbound/loads/pick/up/announcements',
                    name: 'compoundOutboundLoads.pickUpAnnouncements',
                    meta: {},
                    component: () => import('./views/outboundLoads/compoundSuppliers/pickUpAnnouncements'),
                },
                {
                    path: '/d/outbound/loads',
                    name: 'module.driverOutboundLoads',
                    meta: {},
                    component: () => import('./views/outboundLoads/drivers'),
                },
                {
                    path: '/c/outbound/loads',
                    name: 'client.outboundloads',
                    meta: {},
                    component: () => import('./views/outboundLoads/clients'),
                },
                {
                    path: '/additional/services',
                    name: 'folderModule.additionalServices',
                    meta: {},
                    component: () => import('./views/additionalServices'),
                },
                {
                    path: '/zones',
                    name: 'folderModule.zones',
                    meta: {},
                    component: () => import('./views/zones'),
                },
                {
                    path: '/email/settings',
                    name: 'folderModule.emailSettings',
                    meta: {},
                    component: () => import('./views/emailSettings'),
                },
                {
                    path: '/archives/inbound/loads',
                    name: 'folderArchive.inboundLoad',
                    meta: {},
                    component: () => import('./views/archives/inboundLoads'),
                },
                {
                    path: '/archives/outbound/loads',
                    name: 'folderArchive.outboundLoad',
                    meta: {},
                    component: () => import('./views/archives/outboundLoads'),
                },
                {
                    path: '/archives/orders',
                    name: 'folderArchive.orders',
                    meta: {},
                    component: () => import('./views/archives/orders'),
                },
                {
                    path: '/archives/reexport/loads',
                    name: 'folderArchive.reexportLoads',
                    meta: {},
                    component: () => import('./views/archives/reexportLoads'),
                },
                {
                    path: '/archives/dealer/to/dealer/loads',
                    name: 'folderArchive.ddLoads',
                    meta: {},
                    component: () => import('./views/archives/dealerToDealerLoads'),
                },
                {
                    path: '/c/orders', // client
                    name: 'module.clientOrders',
                    meta: {},
                    component: () => import('./views/orders/clients'),
                },
                {
                    path: '/dl/orders',
                    name: 'module.dealerOrders',
                    meta: {},
                    component: () => import('./views/orders/dealers'),
                },
                {
                    path: '/compound/s/orders',
                    name: 'compoundSupplierCompound.stock',
                    meta: {},
                    component: () => import('./views/orders/compoundSuppliers'),
                },
                {
                    path: '/stocked/orders', // EFL -> Order Management -> Compound
                    name: 'module.compoundOrders',
                    meta: {},
                    component: () => import('./views/orders/compound'),
                },
                {
                    path: '/s/h/service/orders', // EFL -> Order Management -> Compound -> Additional Services
                    name: 'module.compoundServiceOrders',
                    meta: {},
                    component: () => import('./views/orders/compound/additionalServices'),
                },
                {
                    path: '/s/h/damages/orders', // EFL -> Order Management -> Compound -> Damages
                    name: 'module.compoundDamages',
                    meta: {},
                    component: () => import('./views/orders/compound/damages'),
                },
                {
                    path: '/compound/s/service/orders',
                    name: 'compoundSupplierCompound.serviceOrders',
                    meta: {},
                    component: () => import('./views/orders/compoundSuppliers/additionalServices'),
                },
                {
                    path: '/compound/s/damages/orders',
                    name: 'compoundSupplierCompound.damages',
                    meta: {},
                    component: () => import('./views/orders/compoundSuppliers/damages'),
                },
                {
                    path: '/dl/outbound/loads',
                    name: 'module.dealerOutboundLoads',
                    meta: {},
                    component: () => import('./views/outboundLoads/dealers'),
                },
                {
                    path: '/dl/inbound/loads',
                    name: 'module.dealerInboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/dealers'),
                },
                {
                    path: '/order/drafts',
                    name: 'module.deliveryForecasts.volumes',
                    meta: {},
                    component: () => import('./views/orderDrafts'),
                },
                {
                    path: '/order/drafts/capacity/confirmations',
                    name: 'module.deliveryForecasts.capacityConfirmation',
                    meta: {},
                    component: () => import('./views/orderDrafts/capacityConfirmation'),
                },
                {
                    path: 'carrier/s/order/drafts',
                    name: 'module.carrierSupplierDeliveryForecasts',
                    meta: {},
                    component: () => import('./views/orderDrafts/carrierSuppliers'),
                },
                {
                    path: '/c/order/drafts',
                    name: 'client.deliveryForecasts',
                    meta: {},
                    component: () => import('./views/orderDrafts/clients'),
                },
                {
                    path: '/mf/inbound/loads',
                    name: 'module.manufacturerInboundLoads',
                    meta: {},
                    component: () => import('./views/inboundLoads/manufacturers'),
                },
                {
                    path: '/compound/s/event/logs',
                    name: 'module.compoundSupplierWebhookLogs',
                    meta: {},
                    component: () => import('./views/webhookLogs'),
                },
                {
                    path: '/annual/forecasts',
                    name: 'module.annualForecasts',
                    meta: {},
                    component: () => import('./views/annualForecasts'),
                },
                {
                    path: '/c/annual/forecasts',
                    name: 'client.annualForecasts',
                    meta: {},
                    component: () => import('./views/annualForecasts/clients'),
                },
                {
                    path: '/c/order/stock/status',
                    name: 'client.stockStatus',
                    meta: {},
                    component: () => import('./views/orders/clients'),
                },
                {
                    path: '/c/order/service/orders',
                    name: 'client.serviceOrders',
                    meta: {},
                    component: () => import('./views/orders/clients/serviceOrders'),
                },
                {
                    path: '/c/order/damages',
                    name: 'client.damages',
                    meta: {},
                    component: () => import('./views/orders/clients/damages'),
                },
                {
                    path: '/c/orders/produced',
                    name: 'clientInboundModule.orders',
                    meta: {},
                    component: () => import('./views/orders/clients/produced'),
                },
                {
                    path: '/reexport/loads',
                    name: 'compoundModule.reExport',
                    meta: {},
                    component: () => import('./views/reexportLoads'),
                },
                {
                    path: '/carrier/s/reexport/loads',
                    name: 'module.carrierSupplierReexport',
                    meta: {},
                    component: () => import('./views/reexportLoads/carrierSuppliers'),
                },
                {
                    path: '/compound/s/reexport/loads',
                    name: 'module.compoundSupplierReexport',
                    meta: {},
                    component: () => import('./views/reexportLoads/compoundSuppliers'),
                },
                {
                    path: '/compound/s/reexport/loads/pick/up/announcements',
                    name: 'compoundReexportLoads.pickUpAnnouncements',
                    meta: {},
                    component: () => import('./views/reexportLoads/compoundSuppliers/pickUpAnnouncements'),
                },
                {
                    path: '/d/reexport/loads',
                    name: 'module.driverSupplierReexport',
                    meta: {},
                    component: () => import('./views/reexportLoads/drivers'),
                },
                {
                    path: '/c/reexport/loads',
                    name: 'module.clientReexport',
                    meta: {},
                    component: () => import('./views/reexportLoads/clients'),
                },
                {
                    path: '/c/locations',
                    name: 'module.clientLocations',
                    meta: {},
                    component: () => import('./views/locations/clients'),
                },
                {
                    path: '/c/suppliers',
                    name: 'module.clientSuppliers',
                    meta: {},
                    component: () => import('./views/suppliers/clients'),
                },
                {
                    path: '/c/contacts',
                    name: 'module.clientContacts',
                    meta: {},
                    component: () => import('./views/contacts/clients'),
                },
                {
                    path: '/dl/orders/delivery/requests',
                    name: 'dealer.deliveryRequests',
                    meta: {},
                    component: () => import('./views/orders/dealers/transportRequests'),
                },
                {
                    path: '/dealer/to/dealer/loads',
                    name: 'module.dealerToDealer',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads'),
                },
                {
                    path: '/carrier/s/dealer/to/dealer/loads',
                    name: 'module.carrierSupplierDealerToDealer',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads/carrierSuppliers'),
                },
                {
                    path: '/d/dealer/to/dealer/loads',
                    name: 'module.driverDealerToDealerLoads',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads/drivers'),
                },
                {
                    path: '/dl/dealer/to/dealer/loads',
                    name: 'module.dealerToDealerForDealer',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads/dealers/vehicleArrivals'),
                },
                {
                    path: '/c/dealer/to/dealer/loads',
                    name: 'module.clientDealerToDealer',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads/clients'),
                },
                {
                    path: '/c/archives/inbound/loads',
                    name: 'folderArchive.clientInboundLoad',
                    meta: {},
                    component: () => import('./views/archives/inboundLoads/clients'),
                },
                {
                    path: '/c/archives/outbound/loads',
                    name: 'folderArchive.clientOutboundLoad',
                    meta: {},
                    component: () => import('./views/archives/outboundLoads/clients'),
                },
                {
                    path: '/c/archives/orders',
                    name: 'folderArchive.clientOrders',
                    meta: {},
                    component: () => import('./views/archives/orders/clients'),
                },
                {
                    path: '/c/archives/reexport/loads',
                    name: 'folderArchive.clientReexportLoads',
                    meta: {},
                    component: () => import('./views/archives/reexportLoads/clients'),
                },
                {
                    path: '/c/archives/dealer/to/dealer/loads',
                    name: 'folderArchive.clientDdLoads',
                    meta: {},
                    component: () => import('./views/archives/dealerToDealerLoads/clients'),
                },
                {
                    path: '/c/archives/spot/loads',
                    name: 'folderArchive.clientSpotLoads',
                    meta: {},
                    component: () => import('./views/archives/spotLoads/clients'),
                },
                {
                    path: '/c/order/api/logs',
                    name: 'module.clientApiLogs',
                    meta: {},
                    component: () => import('./views/orderApiLogs/clients'),
                },
                {
                    path: '/spot/loads',
                    name: 'module.spotLoads',
                    meta: {},
                    component: () => import('./views/spotLoads'),
                },
                {
                    path: '/spot/orders',
                    name: 'module.spotOrders',
                    meta: {},
                    component: () => import('./views/spotLoads/orders'),
                },
                {
                    path: '/carrier/s/spot/loads',
                    name: 'module.carrierSupplierSpotLoads',
                    meta: {},
                    component: () => import('./views/spotLoads/carrierSuppliers'),
                },
                {
                    path: '/driver/spot/loads',
                    name: 'module.driverSupplierSpotLoads',
                    meta: {},
                    component: () => import('./views/spotLoads/drivers'),
                },
                {
                    path: '/archives/spot/loads',
                    name: 'folderArchive.spotLoads',
                    meta: {},
                    component: () => import('./views/archives/spotLoads'),
                },
                {
                    path: '/email/logs',
                    name: 'emailLogs',
                    meta: {},
                    component: () => import('./views/emailLogs'),
                },
                {
                    path: '/auto/inbound/rules',
                    name: 'autoInboundRules',
                    meta: {},
                    component: () => import('./views/autoInboundRules'),
                },
                {
                    path: '/auto/inbound/logs',
                    name: 'autoInboundLogs',
                    meta: {},
                    component: () => import('./views/autoInboundRules/logIndex'),
                },
                {
                    path: '/client/s/event/logs',
                    name: 'module.clientSupplierWebhookLogs',
                    meta: {},
                    component: () => import('./views/webhookLogs/clients'),
                },
                {
                    path: '/analytics',
                    name: 'module.analytics',
                    meta: {},
                    component: () => import('./views/analytics'),
                },
                // {
                //     path: '/system/messages',
                //     name: 'systemMessages',
                //     meta: {},
                //     component: () => import('./views/systemMessages'),
                // },
                {
                    path: '/s/carrier/archives/inbound/loads',
                    name: 'module.archiveSupplierCarrierInboundLoads',
                    meta: {},
                    component: () => import('./views/archives/inboundLoads/carrierSuppliers'),
                },
                {
                    path: '/system/messages',
                    name: 'systemMessages',
                    meta: {},
                    component: () => import('./views/systemMessages'),
                },
                {
                    path: '/auto/transfer/client/rules',
                    name: 'autoTransferClientRules',
                    meta: {},
                    component: () => import('./views/autoTransferClientRules'),
                },
                {
                    path: '/auto/transfer/client/rules/logs',
                    name: 'autoTransferClientRulesLogs',
                    meta: {},
                    component: () => import('./views/autoTransferClientRules/logIndex'),
                },
                {
                    path: '/orders/outbound/transport/requests',
                    name: 'orderOutboundTransportRequests',
                    meta: {},
                    component: () => import('./views/orders/outboundTransportRequests'),
                },
                {
                    path: '/orders/dealer/to/dealer/transport/requests',
                    name: 'module.dealerToDealer',
                    meta: {},
                    component: () => import('./views/orders/dtdTransportRequests'),
                },
                {
                    path: '/price/volumes',
                    name: 'module.loadVolumes',
                    meta: {},
                    component: () => import('./views/prices/volumes'),
                },
                {
                    path: '/s/carrier/archives/outbound/loads',
                    name: 'module.archiveSupplierCarrierOutboundLoads',
                    meta: {},
                    component: () => import('./views/archives/outboundLoads/carrierSuppliers'),
                },
                {
                    path: '/s/carrier/archives/reexport/loads',
                    name: 'module.archiveSupplierCarrierReexportLoad',
                    meta: {},
                    component: () => import('./views/archives/reexportLoads/carrierSuppliers'),
                },
                {
                    path: '/s/carrier/archives/dd/loads',
                    name: 'module.archiveSupplierCarrierDDtLoad',
                    meta: {},
                    component: () => import('./views/archives/dealerToDealerLoads/carrierSuppliers'),
                },
                {
                    path: '/s/carrier/archives/spot/loads',
                    name: 'module.archiveSupplierCarrierSpotLoad',
                    meta: {},
                    component: () => import('./views/archives/spotLoads/carrierSuppliers'),
                },
                {
                    path: '/orders/dealer/to/dealer',
                    name: 'module.manualOrders',
                    meta: {},
                    component: () => import('./views/dealerToDealerLoads/orders'),
                },
                {
                    path: '/compound/s/archives/inbound/loads',
                    name: 'module.archiveSupplierCompoundInboundLoad',
                    meta: {},
                    component: () => import('./views/archives/inboundLoads/compoundSuppliers'),
                },
                {
                    path: '/compound/s/archives/outbound/loads',
                    name: 'module.archiveSupplierCompoundOutboundLoad',
                    meta: {},
                    component: () => import('./views/archives/outboundLoads/compoundSuppliers'),
                },
                {
                    path: '/compound/s/archives/reexport/loads',
                    name: 'module.archiveSupplierCompoundReexportLoad',
                    meta: {},
                    component: () => import('./views/archives/reexportLoads/compoundSuppliers'),
                },
                {
                    path: 'compound/s/archives/orders',
                    name: 'module.archiveSupplierCompoundOrders',
                    meta: {},
                    component: () => import('./views/archives/orders/compoundSuppliers'),
                },
                {
                    path: 'compound/s/archives/spot/loads',
                    name: 'module.archiveSupplierCompoundSpotLoads',
                    meta: {},
                    component: () => import('./views/archives/spotLoads/compoundSuppliers'),
                },
                {
                    path: 'pre/invoices/create',
                    name: 'module.preInvoiceCreate',
                    meta: {},
                    component: () => import('./views/preInvoices/create'),
                },
                {
                    path: 'pre/invoices',
                    name: 'module.preInvoices',
                    meta: {},
                    component: () => import('./views/preInvoices/index'),
                },
            ],
        },

        // System Pages
        {
            path: '/auth',
            component: AuthLayout,
            redirect: 'auth/login',
            children: [
                {
                    path: '/auth/404',
                    name: 'Error404',
                    meta: {
                        title: '404',
                        authRequired: false
                    },
                    component: () => import('./views/auth/404'),
                },
                {
                    path: '/auth/login',
                    name: 'Login',
                    meta: {
                        title: 'Sign In',
                        authRequired: false
                    },
                    component: () => import('./views/auth/login'),
                },
                {
                    path: '/auth/forgot-password',
                    name: 'ForgotPassword',
                    meta: {
                        title: 'Forgot Password',
                        authRequired: false
                    },
                    component: () => import('./views/auth/forgotPassword'),
                },
                {
                    path: '/password/reset/:token?',
                    name: 'ResetPassword',
                    meta: {
                        title: 'Reset Password',
                        authRequired: false
                    },
                    component: () => import('./views/auth/resetPassword'),
                },
                {
                    path: '/password/reenter',
                    name: 'ReenterPassword',
                    meta: {
                        title: 'Reenter Password',
                        authRequired: true
                    },
                    component: () => import('./views/auth/reenterPassword'),
                },
            ],
        },
        // Redirect to 404
        {
            path: '*', redirect: 'auth/404', hidden: true,
        },
        {
            path: '/delivery/request/action/:id',
            name: 'deliveryRequestAction',
            meta: {
                title: 'Deliver Request Action',
            },
            component: () => import('./views/dealers/deliveryRequestAction'),
        },
    ],
})

router.beforeEach((to, from, next) => {
    const user = getAuthUser()

    if (to.matched.some(record => record.meta.authRequired) && !hasAuthUser()) {
        removeStorage('auth')
        const redirectTo = encodeURIComponent(_.trim(window.location.pathname + window.location.search, '/'))
        next({
            name: 'Login',
            query: {
                redirectTo: redirectTo
            }
        })
    } else if (hasAuthUser() && user.has_fpw_changed === false && to.name !== "ReenterPassword") {
        const redirectTo = encodeURIComponent(_.trim(window.location.pathname + window.location.search, '/'))
        next({
            name: 'ReenterPassword',
            query: {
                redirectTo: redirectTo
            }
        })
    } else {
        next()
    }

})

export default router

