<template>
    <span class="text-capitalize">
          <b-badge v-if="status == 1" variant="primary" class="text-white" :title="_type" v-b-tooltip.hover>TR {{(label) ? label : $t('title.requested')}}</b-badge>
          <b-badge v-if="status == 2" variant="danger" :title="_type" class="text-white" v-b-tooltip.hover>TR {{(label) ? label : $t('title.rejected')}}</b-badge>
          <b-badge v-if="status == 3" variant="success" :title="_type" v-b-tooltip.hover>TR {{(label) ? label : $t('title.confirmed')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'type', 'label'],
        data() {
            return {}
        },
        computed: {
            _type: function () {
                if (this.type === 'outbound') {
                    return this.$t('title.outbound');
                }

                if (this.type === 'reexport') {
                    return this.$t('title.reexport');
                }

                if (this.type === 'dd') {
                    return 'D2D';
                }

                return 'CC';
            }
        }
    }
</script>
