<template>
    <span>
        <b-badge v-if="status == 1" variant="primary">{{(label) ? label : $t('title.transportOrder')}}</b-badge>
        <b-badge v-if="status == 2" variant="dark">{{(label) ? label : $t('title.announced')}}</b-badge>
        <b-badge v-if="status == 3" variant="danger">{{(label) ? label : $t('title.rejected')}}</b-badge>
        <b-badge v-if="status == 4" variant="success">{{(label) ? label : $t('title.confirmed')}}</b-badge>
        <b-badge v-if="status == 5" variant="secondary">{{(label) ? label : $t('title.pickUp')}}</b-badge>
        <b-badge v-if="status == 6" variant="warning">{{(label) ? label : $t('title.stockExitInTransit')}}</b-badge>
        <b-badge v-if="status == 7" variant="info">{{(label) ? label : $t('title.deliveryRequested')}}</b-badge>
        <b-badge v-if="status == 8" variant="success">{{(label) ? label : $t('title.delivered')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'label'],
        data() {
            return {

            }
        }
    }
</script>
