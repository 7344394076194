<template>
    <span>
          <b-badge v-if="status == 1" variant="danger">{{(label) ? label : $t('title.pending')}}</b-badge>
          <b-badge v-if="status == 2" variant="success">{{(label) ? label : $t('title.imported')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'label'],
        data() {
            return {}
        }
    }
</script>
