<template>
    <span>
          <b-badge v-if="status == -3" variant="danger" class="text-white">{{(label) ? label : $t('title.pending')}}</b-badge>
          <b-badge v-if="status == -2" variant="danger" class="text-white">{{(label) ? label : $t('title.qualityHoldBlocked')}}</b-badge>
          <b-badge v-if="status == -1" variant="success" class="text-white">{{(label) ? label : $t('title.confirmationOrder')}}</b-badge>
          <b-badge v-if="status == 1" variant="primary" class="text-white">{{(label) ? label : $t('title.produced')}}</b-badge>
          <b-badge v-if="status == 2" variant="light" class="text-dark">{{(label) ? label : $t('title.addedToLoad')}}</b-badge>
          <b-badge v-if="status == 3" variant="success">{{(label) ? label : $t('title.assignedToLoad')}}</b-badge>
          <b-badge v-if="status == 4" variant="info">{{(label) ? label : $t('title.loaded')}}</b-badge>
          <b-badge v-if="status == 5" variant="secondary">{{(label) ? label : $t('title.inTransit')}}</b-badge>
          <b-badge v-if="status == 6" variant="info">{{(label) ? label : $t('title.unloadRequested')}}</b-badge>
          <b-badge v-if="status == 7" variant="dark">{{(label) ? label : $t('title.unloaded')}}</b-badge>
          <b-badge v-if="status == 8" variant="warning">{{(label) ? label : $t('title.stocked')}}</b-badge>
          <b-badge v-if="status == 9" variant="danger">{{(label) ? label : $t('title.hold')}}</b-badge>
          <b-badge v-if="status == 10" variant="primary">{{(label) ? label : $t('title.transportOrder')}}</b-badge>
          <b-badge v-if="status == 11" variant="dark">{{(label) ? label : $t('title.announced')}}</b-badge>
          <b-badge v-if="status == 12" variant="danger">{{(label) ? label : $t('title.rejected')}}</b-badge>
          <b-badge v-if="status == 13" variant="success">{{(label) ? label : $t('title.confirmed')}}</b-badge>
          <b-badge v-if="status == 14" variant="secondary">{{(label) ? label : $t('title.pickUp')}}</b-badge>
          <b-badge v-if="status == 15" variant="warning">{{(label) ? label : $t('title.stockExitInTransit')}}</b-badge>
          <b-badge v-if="status == 16" variant="info">{{(label) ? label : $t('title.deliveryRequested')}}</b-badge>
          <b-badge v-if="status == 17" variant="success">{{(label) ? label : $t('title.delivered')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'label'],
        data() {
            return {

            }
        }
    }
</script>
