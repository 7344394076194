import {getMenuData as baseMenu} from "./base.menu";

/**
* This is the navigation for all Clients
*/
export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: self.$t('topBar.navigations.modules.inboundLoads'),
            name: 'module.manufacturerInboundLoads',
            key: 'module.manufacturerInboundLoads',
            icon: 'fe fe-circle',
            url: '/mf/inbound/loads',
            hasAnyPermission: ['inboundloadsownview'],
            hasAllPermissions: [],
        },
    ];
}
