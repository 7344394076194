export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fa fa-tachometer',
            url: '/dashboard/alpha',
            hasAllPermissions: []
        },

        {
            title: self.$t('topBar.navigations.inbound'),
            key: 'inboundModule',
            name: 'orderModule',
            icon: 'fa fa-legal',
            hasAnyPermission: ['inboundloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'inboundModule.inboundloads',
                    name: 'inboundModule.inboundloads',
                    url: '/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.compound'),
            key: 'compoundModule',
            name: 'compoundModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['inboundloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'compoundModule.compoundServiceOrders',
                    name: 'compoundModule.compoundServiceOrders',
                    url: '/s/h/service/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'compoundModule.compoundDamages',
                    name: 'compoundModule.compoundDamages',
                    url: '/s/h/damages/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'compoundModule.reExport',
                    name: 'compoundModule.reExport',
                    icon: 'fa fa-dot-circle-o',
                    url: '/reexport/loads',
                    hasAnyPermission: ['reexportloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.outbound'),
            key: 'outboundModule',
            name: 'outboundModule',
            icon: 'fa fa-sun-o',
            hasAnyPermission: ['outboundloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'outboundModule.outboundloads',
                    name: 'outboundModule.outboundloads',
                    url: '/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'module.dealerToDealer',
                    name: 'module.dealerToDealer',
                    url: '/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ddloadsview'],
                    hasAllPermissions: [],
                },
            ]
        },

        {
            title: self.$t('topBar.navigations.modules.spot'),
            key: 'spot.loads',
            name: 'spot.loads',
            url: '/spot/loads',
            icon: 'fe fe-feather',
            hasAnyPermission: ['spotloadsview'],
        },

        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['clientsview', 'suppliersview', 'manufacturersview', 'contactsview', 'pricesview', 'dealersview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.clients'),
                    key: 'module.clients',
                    name: 'module.clients',
                    url: '/clients',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['clientsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.suppliers'),
                    key: 'module.suppliers',
                    name: 'module.suppliers',
                    url: '/suppliers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['suppliersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.manufacturers'),
                    key: 'module.manufacturers',
                    name: 'module.manufacturers',
                    url: '/manufacturers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['manufacturersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dealers'),
                    key: 'module.dealers',
                    name: 'module.dealers',
                    url: '/dealers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['dealersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.prices'),
                    key: 'module.prices',
                    name: 'module.prices',
                    url: '/prices',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['pricesview'],
                },
            ],
        },

        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveModule',
            name: 'archiveModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveinboundloadview', 'archiveoutboundloadview', 'archiveorderview', 'archivereexportloadsview', 'archiveddloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'folderArchive.inboundLoad',
                    name: 'folderArchive.inboundLoad',
                    url: '/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveinboundloadview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'folderArchive.outboundLoad',
                    name: 'folderArchive.outboundLoad',
                    url: '/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveoutboundloadview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'folderArchive.orders',
                    name: 'folderArchive.orders',
                    url: '/archives/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveorderview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'folderArchive.reexportLoads',
                    name: 'folderArchive.reexportLoads',
                    url: '/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archivereexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'folderArchive.dealerToDealerLoads',
                    name: 'folderArchive.dealerToDealerLoads',
                    url: '/archives/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveddloadsview'],
                },
            ]
        },

    ]
}
