<template>
    <span>
        <template v-if="item">
            <span v-if="titled"
                  :title="item.street + ', ' + item.street_no + ', ' + item.city + ', ' + item.zip + ', ' + item.country"
                  v-b-tooltip.hover>
                <span>
                    <img :src="'/resources/images/flags/'+item.country_sort+'.png'" class="mr-2">
                </span>
                {{item.city}}
            </span>
            <span v-else>
                {{item.street}},
                {{item.street_no}},
                {{item.city}},
                {{item.zip}},
                {{item.country}}
            </span>
        </template>
    </span>
</template>
<script>
    export default {
        props: ['item', 'titled'],
        data() {
            return {}
        }
    }
</script>
