<template>
	<div :class="$style.breadcrumbs">
		<div :class="$style.path">
			<router-link to="/">Home</router-link>
			<template v-for="(item, index) in breadcrumb">
        <span v-if="index != 0" :key="index">
          <span :class="$style.arrow"></span>
          <span>{{item.title}}</span>
        </span>
			</template>
			<span v-if="activeItem">
        <span :class="$style.arrow"></span>
        <strong :class="$style.current">{{activeItem.title}}</strong>
      </span>
		</div>
	</div>
</template>

<script>
    import {mapState} from 'vuex'
    import reduce from 'lodash/reduce'
    import {hasRole} from "../../../../util/Utils";
    import {getMenuData} from '../../../../navigation/base.menu'
    import {getMenuData as getSuperAdminMenu} from '../../../../navigation/superadmin'
    import {getMenuData as getClientMenu} from '../../../../navigation/client'
    import {getMenuData as getSupplierMenu} from '../../../../navigation/supplier'
    import {getMenuData as getEFLMenu} from '../../../../navigation/efl'
    import {getMenuData as getDealer} from '../../../../navigation/dealer'
    import {getMenuData as getDriver} from '../../../../navigation/driver'
    import {getMenuData as getManufacturer} from '../../../../navigation/manufacturer'

    export default {
        name: 'breadcrumbs',
        data() {
            return {
                breadcrumb: [],
                activeItem: {},
                path: [],
            }
        },
        computed: {
            ...mapState(['settings']),
            menuData() {
                return this.getNavBasedOnRole()
            },
        },
        methods: {
            getPath(data, url, parents = []) {
                if (url === '/') {
                    url = '/dashboard/alpha'
                }
                const items = reduce(
                    data,
                    (result, entry) => {
                        if (result.length) {
                            return result
                        }
                        if (entry.children) {
                            const nested = this.getPath(entry.children, url, [entry].concat(parents))
                            return (result || []).concat(nested.filter(e => !!e))
                        }
                        if (entry.url === url) {
                            return [entry].concat(parents)
                        }
                        return result
                    },
                    [],
                )
                this.activeItem = items[0]
                return items
            },
            getNavBasedOnRole() {
                if (hasRole('superadmin')) {
                    return getSuperAdminMenu(this);
                } else if (hasRole('suppliercarrier') || hasRole('supplierra') || hasRole('suppliercompound')) {
                    return _.compact(getSupplierMenu(this));
                } else if (hasRole('client')) {
                    return getClientMenu(this);
                } else if (hasRole('efl')) {
                    return getEFLMenu(this);
                } else if (hasRole('driver')) {
                    return getDriver(this);
                } else if (hasRole('dealer')) {
                    return getDealer(this);
                } else if (hasRole('manufacturer')) {
                    return getManufacturer(this);
                }

                return getMenuData(this);
            }
        },
        mounted: function () {
            this.breadcrumb = this.getPath(this.menuData, this.$route.path)
        },
        watch: {
            $route(to) {
                this.breadcrumb = this.getPath(this.menuData, to.path)
            },
        },
    }
</script>

<style lang="scss" module>
	@import "./style.module.scss";
</style>
