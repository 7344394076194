<template>
    <div :class="$style.topbar">
        <div class="mr-4">
            <cui-menu-toggle/>
        </div>
        <div class="mr-auto text-danger line-height-1 w-100">
            <span :title="$t('msc.weAreStillInTheTestPhaseAndTheProcessWillContinueToBeDoneManuallyUntilTheEndOfTheTestPhase')">
<!--                v-if="$global.hasRole('suppliercarrier')"-->
				{{$t('msc.weAreStillInTheTestPhaseAndTheProcessWillContinueToBeDoneManuallyUntilTheEndOfTheTestPhase')}}
			</span>
        </div>
        <div class="mr-4 d-none d-md-block spinner-middle">
            <b-spinner v-show="global.pendingRequests > 0" label="Loading..." variant="success" small></b-spinner>
        </div>
        <div class="mb-0 mr-auto d-xl-block d-none">

        </div>
        <div class="mr-4 d-none d-sm-block">
        </div>
        <div class="mr-4 d-none d-sm-block">
            <cui-language-switcher/>
        </div>
        <div>
            <cui-user-menu/>
        </div>
    </div>
</template>

<script>
    import CuiUserMenu from './UserMenu'
    import CuiMenuToggle from './../MenuToggle'
    import CuiLanguageSwitcher from './LanguageSwitcher'
    import {mapState} from "vuex";
    import {request} from "../../../../util/Request";
    import moment from 'moment-timezone'

    export default {
        components: {
            CuiUserMenu,
            CuiLanguageSwitcher,
            CuiMenuToggle
        },
        computed: {
            ...mapState([
                'global'
            ]),
        },
    }
</script>

<style>
    i.fa.fa-close {
        cursor: pointer;
    }
</style>

<style lang="scss" module>
    @import "./style.module.scss";
</style>
