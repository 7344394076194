export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fa fa-tachometer',
            url: '/dashboard/alpha',
            hasAllPermissions: ['superadmindashboardview'],
        },
        {
            title: self.$t('topBar.navigations.categories.forecasts'),
            key: 'forecasts',
            name: 'forecasts',
            icon: 'fa fa-check-square',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            category: 1,
        },
        {
            title: 'ATN',
            key: 'deliveryForecasts',
            name: 'orderModule.deliveryForecasts',
            url: '/order/drafts',
            icon: 'fa fa-align-left',
            hasAnyPermission: ['ordersview'],
        },
        {
            title: self.$t('topBar.navigations.modules.yearlyForecasts'),
            key: 'annualForecasts',
            name: 'annualForecasts',
            url: '/annual/forecasts',
            icon: 'fa fa-align-right',
            hasAnyPermission: [],
        },
        {
            title: self.$t('topBar.navigations.categories.orderManagement'),
            key: 'orderModule',
            name: 'orderModule',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            category: 2,
        },
        {
            title: self.$t('topBar.navigations.inbound'),
            key: 'inboundModule',
            name: 'orderModule',
            icon: 'fa fa-legal',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'inboundModule.orders',
                    name: 'inboundModule.orders',
                    url: '/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'inboundModule.inboundloads',
                    name: 'inboundModule.inboundloads',
                    url: '/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.archived'),
                    key: 'inboundModule.archived',
                    name: 'inboundModule.archived',
                    url: '/archived/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.compound'),
            key: 'compoundModule',
            name: 'compoundModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['ordersview', 'inboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'compoundModule.compoundOrders',
                    name: 'compoundModule.compoundOrders',
                    url: '/stocked/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'compoundModule.compoundServiceOrders',
                    name: 'compoundModule.compoundServiceOrders',
                    url: '/s/h/service/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'compoundModule.compoundDamages',
                    name: 'compoundModule.compoundDamages',
                    url: '/s/h/damages/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'compoundModule.reExport',
                    name: 'compoundModule.reExport',
                    url: 'javascript:;',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.outbound'),
            key: 'outboundModule',
            name: 'outboundModule',
            icon: 'fa fa-sun-o',
            hasAnyPermission: ['ordersview', 'outboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'outboundModule.outboundloads',
                    name: 'outboundModule.outboundloads',
                    url: '/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'outboundModule.dtdTransports',
                    name: 'outboundModule.dtdTransports',
                    url: 'javascript:;',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsownview'],
                    hasAllPermissions: [],
                }
            ]
        },
        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['clientsview', 'suppliersview', 'manufacturersview', 'contactsview', 'pricesview', 'dealersview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.clients'),
                    key: 'module.clients',
                    name: 'module.clients',
                    url: '/clients',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['clientsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.suppliers'),
                    key: 'module.suppliers',
                    name: 'module.suppliers',
                    url: '/suppliers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['suppliersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.manufacturers'),
                    key: 'module.manufacturers',
                    name: 'module.manufacturers',
                    url: '/manufacturers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['manufacturersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dealers'),
                    key: 'module.dealers',
                    name: 'module.dealers',
                    url: '/dealers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['dealersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/contacts',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.prices'),
                    key: 'module.prices',
                    name: 'module.prices',
                    url: '/prices',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['pricesview'],
                },
            ],
        },
        {
            title: self.$t('topBar.navigations.accessManagement'),
            key: 'folderUserManagement',
            name: 'folderUserManagement',
            icon: 'fa fa-users',
            hasAnyPermission: ['usersview', 'rolesview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.users'),
                    key: 'folderUserManagement.users',
                    name: 'folderUserManagement.users',
                    url: '/users',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['usersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.eflUsers'),
                    key: 'folderUserManagement.eflUsers',
                    name: 'folderUserManagement.eflUsers',
                    url: '/users/efl',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['eflusersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.userRoles'),
                    key: 'folderUserManagement.roles',
                    name: 'folderUserManagement.roles',
                    url: '/roles',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['rolesview'],
                },
            ],
        },
        {
            title: self.$t('topBar.navigations.settings'),
            key: 'folderExplore',
            name: 'folderExplore',
            icon: 'fa fa-cogs',
            hasAnyPermission: [
                'driversview', 'contractsview', 'locationtypesview', 'locationsview',
                'routesview', 'brandsview', 'modelsview', 'transportationtypesview',
                'transportvehiclesview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.drivers'),
                    key: 'module.drivers',
                    name: 'module.drivers',
                    url: '/drivers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['driversview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.contracts'),
                    key: 'module.contracts',
                    name: 'module.contracts',
                    url: '/contracts',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contractsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locationCodes'),
                    key: 'module.locationcodes',
                    name: 'module.locationcodes',
                    url: '/location/codes',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationcodesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locationTypes'),
                    key: 'module.locationtypes',
                    name: 'module.locationtypes',
                    url: '/location/types',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationtypesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/locations',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.routes'),
                    key: 'module.routes',
                    name: 'module.routes',
                    url: '/routes',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['routesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.brands'),
                    key: 'module.brands',
                    name: 'module.brands',
                    url: '/brands',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['brandsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.models'),
                    key: 'module.models',
                    name: 'module.models',
                    url: '/models',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['modelsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.logisticTypes'),
                    key: 'module.logisticTypes',
                    name: 'module.logisticTypes',
                    url: '/logistic/types',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['logistictypesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportVehicles'),
                    key: 'module.transportvehicles',
                    name: 'module.transportvehicles',
                    url: '/transport/vehicles',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['transportvehiclesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.additionalServices'),
                    key: 'folderModule.additionalservices',
                    name: 'folderModule.additionalservices',
                    url: '/additional/services',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['additionalservicesview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.zones'),
                    key: 'folderModule.zones',
                    name: 'folderModule.zones',
                    url: '/zones',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['zonesview'],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.autoInboundRules'),
            key: 'folderModule.autoInboundRules',
            name: 'folderModule.autoInboundRules',
            url: '/auto/inbound/rules',
            icon: 'fa fa-dot-circle-o',
            hasAnyPermission: [],
            hasAllPermissions: ['autoinboundrulesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.autoInboundLogs'),
            key: 'folderModule.autoInboundRuleLogs',
            name: 'folderModule.autoInboundRuleLogs',
            url: '/auto/inbound/rules/logs',
            icon: 'fa fa-dot-circle-o',
            hasAnyPermission: [],
            hasAllPermissions: ['autoinboundrulesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.analytics'),
            key: 'analytics',
            name: 'analytics',
            url: '/analytics',
            icon: 'fa fa-area-chart',
            hasAllPermissions: ['analyticsview'],
        },
        {
            title: self.$t('topBar.navigations.modules.preInvoices'),
            key: 'preInvoices',
            name: 'preInvoices',
            url: '/pre/invoices',
            icon: 'fa fa-area-chart',
            hasAllPermissions: ['preinvoicesview'],
        },
        {
            title: self.$t('topBar.navigations.modules.createInvoice'),
            key: 'preInvoiceCreate',
            name: 'preInvoiceCreate',
            url: '/pre/invoices/create',
            icon: 'fa fa-area-chart',
            hasAllPermissions: ['preinvoicesstore'],
        },
    ]
}
