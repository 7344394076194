import {getMenuData as baseMenu} from "./base.menu";

/**
* This is the navigation for all Clients
*/
export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: self.$t('topBar.navigations.categories.forecasts'),
            key: 'client.forecasts',
            name: 'client.forecasts',
            icon: 'fa fa-check-square',
            hasAnyPermission: [],
            category: 1,
        },
        {
            title: 'ATN',
            name: 'client.deliveryForecasts',
            key: 'client.deliveryForecasts',
            icon: 'fe fe-circle',
            url: '/c/order/drafts',
            hasAnyPermission: ['orderdraftsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.yearlyForecasts'),
            name: 'client.annualForecasts',
            key: 'client.annualForecasts',
            icon: 'fe fe-circle',
            url: '/c/annual/forecasts',
            hasAnyPermission: ['annualforecastsview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.categories.orderManagement'),
            key: 'client.orderModule',
            name: 'client.orderModule',
            hasAnyPermission: [],
            category: 2,
        },
        {
            title: self.$t('topBar.navigations.inbound'),
            key: 'clientInboundModule',
            name: 'clientInboundModule',
            icon: 'fe fe-circle',
            hasAnyPermission: ['ordersownview', 'inboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'clientInboundModule.orders',
                    name: 'clientInboundModule.orders',
                    url: '/c/orders/produced',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'clientInboundModule.inboundloads',
                    name: 'clientInboundModule.inboundloads',
                    url: '/c/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['inboundloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.compound'),
            key: 'clientCompoundModule',
            name: 'clientCompoundModule',
            icon: 'fe fe-circle',
            hasAnyPermission: ['ordersownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.stockStatus'),
                    key: 'client.stockStatus',
                    name: 'client.stockStatus',
                    url: '/c/order/stock/status',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.serviceOrders'),
                    key: 'client.serviceOrders',
                    name: 'client.serviceOrders',
                    url: '/c/order/service/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.damages'),
                    key: 'client.damages',
                    name: 'client.damages',
                    url: '/c/order/damages',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ordersownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.outbound'),
            key: 'clientOutboundModule',
            name: 'clientOutboundModule',
            icon: 'fa fa-sun-o',
            hasAnyPermission: ['outboundloadsownview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.transportOrders'),
                    key: 'client.outboundloads',
                    name: 'client.outboundloads',
                    url: '/c/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['outboundloadsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'client.dtdTransports',
                    name: 'client.dtdTransports',
                    url: '/c/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['ddloadsownview'],
                    hasAllPermissions: [],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'client.reExport',
                    name: 'client.reExport',
                    url: '/c/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: ['reexportloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.baseDataManagement'),
            key: 'module',
            name: 'module',
            icon: 'fa fa-arrow-circle-o-d',
            hasAnyPermission: ['suppliersownview', 'locationsownview'],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.suppliers'),
                    key: 'module.suppliers',
                    name: 'module.suppliers',
                    url: '/c/suppliers',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['suppliersownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.locations'),
                    key: 'module.locations',
                    name: 'module.locations',
                    url: '/c/locations',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['locationsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.contacts'),
                    key: 'module.contacts',
                    name: 'module.contacts',
                    url: '/c/contacts',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['contactsownview'],
                },
            ],
        },
        {
            title: self.$t('topBar.navigations.modules.archive'),
            key: 'archiveClientModule',
            name: 'archiveClientModule',
            icon: 'fa fa-lemon-o',
            hasAnyPermission: ['archiveowninboundloadsview', 'archiveownoutboundloadsview', 'archiveownordersview', 'archiveownreexportloadsview', 'archiveownddloadsview', 'archiveownspotloadsview'],
            children:[
                {
                    title: self.$t('topBar.navigations.modules.inboundLoad'),
                    key: 'archiveClientModule.inboundLoad',
                    name: 'archiveClientModule.inboundLoad',
                    url: '/c/archives/inbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveowninboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.outboundLoad'),
                    key: 'archiveClientModule.outboundLoad',
                    name: 'archiveClientModule.outboundLoad',
                    url: '/c/archives/outbound/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownoutboundloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.orders'),
                    key: 'archiveClientModule.orders',
                    name: 'archiveClientModule.orders',
                    url: '/c/archives/orders',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownordersview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.reexport'),
                    key: 'archiveClientModule.reexportLoads',
                    name: 'archiveClientModule.reexportLoads',
                    url: '/c/archives/reexport/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownreexportloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.dtdTransports'),
                    key: 'archiveClientModule.dealerToDealerLoads',
                    name: 'archiveClientModule.dealerToDealerLoads',
                    url: '/c/archives/dealer/to/dealer/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownddloadsview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.spot'),
                    key: 'archiveClientModule.spotLoads',
                    name: 'archiveClientModule.spotLoads',
                    url: '/c/archives/spot/loads',
                    icon: 'fa fa-dot-circle-o',
                    hasAnyPermission: [],
                    hasAllPermissions: ['archiveownspotloadsview'],
                },
            ]
        },
        {
            title: self.$t('topBar.navigations.modules.apiLogs'),
            name: 'client.apiLog',
            key: 'client.apiLog',
            icon: 'fe fe-circle',
            url: '/c/order/api/logs',
        },
        {
            title: self.$t('topBar.navigations.modules.webhookLogs'),
            key: 'module.clientSupplierWebhookLogs',
            name: 'module.clientSupplierWebhookLogs',
            url: '/client/s/event/logs',
            icon: 'fe fe-circle',
        },
    ];
}
