<template>
    <span>
          <b-badge v-if="status == 1" variant="primary">{{(label) ? label : $t('title.created')}}</b-badge>
          <b-badge v-if="status == 2" variant="dark">{{(label) ? label : $t('title.announced')}}</b-badge>
          <b-badge v-if="status == 3" variant="danger">{{(label) ? label : $t('title.rejected')}}</b-badge>
          <b-badge v-if="status == 4" variant="success">{{(label) ? label : $t('title.accepted')}}</b-badge>
          <b-badge v-if="status == 5" variant="info">{{(label) ? label : $t('title.loaded')}}</b-badge>
          <b-badge v-if="status == 6" variant="secondary">{{(label) ? label : $t('title.inTransit')}}</b-badge>
          <b-badge v-if="status == 7" variant="info">{{(label) ? label : $t('title.unloadRequested')}}</b-badge>
          <b-badge v-if="status == 8" variant="success">{{(label) ? label : $t('title.unloadedCompound')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['status', 'label'],
        data() {
            return {

            }
        }
    }
</script>
