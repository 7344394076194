<template>
    <span>
          <b-badge v-if="type == 1" variant="info">{{(label) ? label : $t('title.forACustomer')}}</b-badge>
          <b-badge v-if="type == 2" variant="success">{{(label) ? label : $t('title.forAStock')}}</b-badge>
    </span>
</template>
<script>
    export default {
        props: ['type', 'label'],
        data() {
            return {}
        }
    }
</script>
