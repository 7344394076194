import 'ant-design-vue/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import VueLayers from 'vuelayers'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './antd'
import './registerServiceWorker'
import SUpload from './components/custom/SUpload'
import SMulitpleUpload from './components/custom/SMulitpleUpload'
import InboundLoadStatus from './components/custom/InboundLoadStatus'
import TransportName from './components/custom/TransportName'
import InboundLoadApproval from "./components/custom/InboundLoadApproval";
import OrderLoadStatus from './components/custom/OrderLoadStatus'
import OutboundLoadStatus from './components/custom/OutboundLoadStatus'
import OrderDraftStatus from "./components/custom/OrderDraftStatus"
import OrderType from "./components/custom/OrderType"
import LocationCodeClipboard from './components/custom/LocationCodeClipboard'
import AtnOfferStatus from './components/custom/AtnOfferStatus'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ClipLoader from 'vue-spinner/src/ClipLoader'
import Datepicker from 'vuejs-datepicker'
import {i18n} from './localization'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import _ from 'lodash'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Notification from "./components/custom/Notification"

import {
    formatDate,
    handleSyncRequestLoader,
    hasAllPermissions,
    hasAnyPermission,
    hasPermission,
    hasRole,
    utcDateToLocalDate,
    dateToUtcDate, hasAnyRole, val,
} from "./util/Utils";
import NotifyMixin from "./util/NotifyMixin";
import OrderTrStatus from "./components/custom/OrderTrStatus";
import PrintAddress from "./components/custom/PrintAddress";
import ReexportLoadStatus from "./components/custom/ReexportLoadStatus";
import Fragment from 'vue-fragment'

Vue.use(Fragment.Plugin)
Vue.use(BootstrapVue)
Vue.use(VueLayers)
Vue.component('v-select', vSelect)
Vue.component('clip-loader', ClipLoader)
Vue.component('upload', SUpload)
Vue.component('multi-upload', SMulitpleUpload)
Vue.component('datepicker', Datepicker)
Vue.component('print-ad', PrintAddress)
Vue.component('ol-status', OrderLoadStatus)
Vue.component('ibl-status', InboundLoadStatus)
Vue.component('transport-name', TransportName)
Vue.component('ibla-status', InboundLoadApproval)
Vue.component('re-status', ReexportLoadStatus)
Vue.component('obl-status', OutboundLoadStatus)
Vue.component('tr-status', OrderTrStatus)
Vue.component('od-status', OrderDraftStatus)
Vue.component('od-type', OrderType)
Vue.component('l-code-clipboard', LocationCodeClipboard)
Vue.component('ao-status', AtnOfferStatus)
Vue.component('notifications',Notification)
Vue.use(CKEditor);

Vue.use(VuePageTitle, {
    prefix: 'Emil Frey | ',
    router,
})


Vue.config.productionTip = false

Vue.prototype.$global = {
    hasPermission: (permission) => hasPermission(permission),
    hasAnyPermission: (permissions) => hasAnyPermission(permissions),
    hasAllPermissions: (permissions) => hasAllPermissions(permissions),
    hasRole: (role) => hasRole(role),
    val: (value) => val(value),
    hasAnyRole: (roles) => hasAnyRole(roles),
    dateFormat: (date, format = 'DD.MM.YYYY') => ((date) ? formatDate(date, format) : null),
    utcDateToLocalDate: (date, toFormat = 'DD.MM.YYYY HH:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss') => utcDateToLocalDate(date, toFormat, fromFormat),
    dateToUtcDate: (date, fromFormat = 'YYYY-MM-DD[T]HH:mm:ss', toFormat = 'DD.MM.YYYY HH:mm:ss') => dateToUtcDate(date, fromFormat, toFormat),
    baseUrl: process.env.VUE_APP_API_URL,
};

Vue.prototype._ = _
Vue.mixin(NotifyMixin)

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    created() {
        handleSyncRequestLoader(store, process.env.VUE_APP_API_URL);
    },
}).$mount('#app')
