<template>
    <span>
          <b-badge v-if="status == 1" variant="primary">{{(label) ? label : $t('title.requestSent')}}</b-badge>
          <b-badge v-if="status == 2" variant="warning">{{(label) ? label : $t('title.reminderSent')}}</b-badge>
          <b-badge v-if="status == 3" variant="success">{{(label) ? label : $t('title.atnFullyConfirmed')}}</b-badge>
          <b-badge v-if="status == 4" variant="warning">{{(label) ? label : $t('title.atnFullyAcceptedWithOffer')}}</b-badge>
          <b-badge v-if="status == 5" variant="info">{{(label) ? label : $t('title.atnPartiallyAccepted')}}</b-badge>
          <b-badge v-if="status == 6" variant="danger">{{(label) ? label : $t('title.atnRejected')}}</b-badge>
          <b-badge v-if="status == 7" variant="success">{{(label) ? label : $t('title.eflApproved')}}</b-badge>
          <b-badge v-if="status == 8" variant="danger">{{(label) ? label : $t('title.eflRejected')}}</b-badge>
    </span>
</template>
<script>
export default {
    props: ['status', 'label', 'acceptance-type'],
    data() {
        return {}
    }
}
</script>
