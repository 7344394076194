const DEFAULT_OPTIONS = {
    placement: 'topRight',
    message: '',
    description: ''
}

export default {
    data() {
        return {}
    },
    methods: {
        loginSuccess(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.loginSuccessful'),
                description: (description ? this.$t(description) : this.$t('notifications.description.welcomeBack'))
            })
        },
        loginFailed(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.loginFailed'),
                description: (description ? this.$t(description) : this.$t('notifications.description.pleaseTryAgain'))
            })
        },
        sessionExpired(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.sessionExpired'),
                description: (description ? this.$t(description) : this.$t('notifications.description.pleaseLoginAgain'))
            })
        },
        serverError(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsServerError'),
                description: (description ? this.$t(description) : this.$t('notifications.description.pleaseRefreshPage'))
            })
        },
        notFound(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsError'),
                description: (description ? this.$t(description) : this.$t('notifications.description.unableToFindData'))
            })
        },
        handleServerError(errors) {
            if (errors && ((errors.request && errors.request.status && errors.request.status === 404) || (errors.status && errors.status === 404))) {
                this.notFound();
                return false;
            }

            this.serverError();
        },
        verifyEmailSuccess(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.accountVerificationSuccessful'),
                description: (description ? this.$t(description) : this.$t('notifications.description.loginNow'))
            })
        },
        verifyEmailFailed(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.accountVerificationFailed'),
                description: (description ? this.$t(description) : this.$t('notifications.description.pleaseTryAgain'))
            })
        },
        itemAdded(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.congratulations'),
                description: (description ? this.$t(description) : this.$t('notifications.description.itemSuccessfullyAdded'))
            })
        },
        itemUpdated(description) {
            this.$notification.warning({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.congratulations'),
                description: (description ? this.$t(description) : this.$t('notifications.description.itemSuccessfullyUpdated'))
            })
        },
        itemEditFails(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsError'),
                description: (description ? this.$t(description) : this.$t('notifications.description.unableToFindItem'))
            })
        },
        itemDeleted(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.congratulations'),
                description: (description ? this.$t(description) : this.$t('notifications.description.itemSuccessfullyDeleted'))
            })
        },
        itemDeleteFails(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsError'),
                description: (description ? this.$t(description) : this.$t('notifications.description.unableToDeleteItem'))
            })
        },
        itemDeleteFailsBecsDependency(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.otherObjectsDependOnIt'))
            })
        },
        forgotPasswordSuccess(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.success'),
                description: (description ? this.$t(description) : this.$t('notifications.description.checkInbox'))
            })
        },
        resetPasswordSuccess(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.resetPasswordSuccess'),
                description: (description ? this.$t(description) : this.$t('notifications.description.loginNow'))
            })
        },
        newPasswordSet(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.newPasswordSet'),
                description: (description ? this.$t(description) : this.$t('notifications.description.welcome'))
            })
        },
        invalidUpload(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.invalidUploadFIle'))
            })
        },
        fileSizeLimitError(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.fileSizeTooBig'))
            })
        },
        loadCreateSuccess(description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.congratulations'),
                description: (description ? this.$t(description) : this.$t('notifications.description.loadsSuccessfullyCreated'))
            })
        },
        validationError(description) {
            this.$notification.warning({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsWarning'),
                description: (description ? this.$t(description) : this.$t('notifications.description.requiredFieldsMissing'))
            })
        },
        locationUpdateFailsBecsDependency(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.locationDependencyMsg'))
            })
        },
        routeUpdateFailsBecsDependency(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.routeDependencyMsg'))
            })
        },
        priceUpdateFailsBecsDependency(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.priceDependencyMsg'))
            })
        },
        damageAdditionalServiceExist(description) {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: (description ? this.$t(description) : this.$t('notifications.description.damageAdditionalServiceExistMsg'))
            })
        },
        csvImportSuccess(rows = 0,updateRows = 0, description) {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.success'),
                description: (description ? this.$t(description) : rows + ": " + this.$t('notifications.description.importSuccess')+','+ updateRows + ": " + this.$t('notifications.description.importUpdateSuccess'))
            })
        },
        priceNotFound() {
            this.$notification.error({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoops'),
                description: this.$t('notifications.description.priceNotFound')
            })
        },
        autoRunFinishedCheckLog() {
            this.$notification.success({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.success'),
                description: this.$t('notifications.description.autoRunProcessFinished')
            })
        },
        orderDraftExist() {
            this.$notification.warning({
                ...DEFAULT_OPTIONS,
                message: this.$t('notifications.title.whoopsWarning'),
                description: (this.$t('notifications.description.orderDraftExist'))
            })
        },
    }
}
