import {getMenuData as baseMenu} from "./base.menu";

/**
* This is the navigation for all Clients
*/
export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: self.$t('topBar.navigations.modules.orders'), // will display in Menu
            name: 'module.dealerOrders',
            key: 'module.dealerOrders',
            icon: 'fe fe-circle',
            url: '/dl/orders',
            hasAnyPermission: ['ordersownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.inbound'),
            name: 'module.dealerInboundLoads',
            key: 'module.dealerInboundLoads',
            icon: 'fe fe-circle',
            url: '/dl/inbound/loads',
            hasAnyPermission: ['inboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            name: 'module.dealerOutboundLoads',
            key: 'module.dealerOutboundLoads',
            icon: 'fe fe-circle',
            url: '/dl/outbound/loads',
            hasAnyPermission: ['outboundloadsownview'],
            hasAllPermissions: [],
        },

        {
            title: self.$t('topBar.navigations.modules.dtdTransports'),
            key: 'dealerDtdModule',
            name: 'dealerDtdModule',
            icon: 'fa fa-arrow-circle-o-down',
            hasAnyPermission: ['ddloadsownview'],
            hasAllPermissions: [],
            children: [
                {
                    title: self.$t('topBar.navigations.modules.vehicleArrivals'),
                    key: 'dealerDtdModule.vehicleArrivals',
                    name: 'dealerDtdModule.vehicleArrivals',
                    url: '/dl/dealer/to/dealer/loads',
                    icon: 'fe fe-circle',
                    hasAnyPermission: [],
                    hasAllPermissions: ['ddloadsownview'],
                },
                {
                    title: self.$t('topBar.navigations.modules.deliveryRequests'),
                    key: 'dealerDtdModule.deliveryRequests',
                    name: 'dealerDtdModule.deliveryRequests',
                    url: '/dl/orders/delivery/requests',
                    icon: 'fe fe-circle',
                    hasAnyPermission: ['ddloadsownview'],
                    hasAllPermissions: [],
                },
            ]
        },
    ];
}
