import {getMenuData as baseMenu} from "./base.menu";

/**
 * This is the navigation for all Driver
 */
export function getMenuData(self) {
    return [
        {
            title: self.$t('topBar.navigations.dashboards'), // will display in Menu
            name: 'Dashboard',
            key: 'dashboards',
            icon: 'fe fe-home',
            url: '/dashboard/alpha',
        },
        {
            title: self.$t('topBar.navigations.modules.inbound'),
            key: 'module.driverInboundLoads',
            name: 'module.driverInboundLoads',
            url: '/d/inbound/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['inboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.outbound'),
            key: 'module.driverOutboundLoads',
            name: 'module.driverOutboundLoads',
            url: '/d/outbound/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['outboundloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.reexport'),
            key: 'module.driverReexportLoads',
            name: 'module.driverReexportLoads',
            url: '/d/reexport/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['reexportloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.dtdTransports'),
            key: 'module.driverDealerToDealerLoads',
            name: 'module.driverDealerToDealerLoads',
            url: '/d/dealer/to/dealer/loads',
            icon: 'fe fe-circle',
            hasAnyPermission: ['ddloadsownview'],
            hasAllPermissions: [],
        },
        {
            title: self.$t('topBar.navigations.modules.spot'),
            key: 'module.driverSupplierSpotLoads',
            name: 'module.driverSupplierSpotLoads',
            url: '/driver/spot/loads',
            icon: 'fa fa-circle',
            hasAnyPermission: ['spotloadsownview'],
            hasAllPermissions: [],
        },
    ]
}
