<template>
	<div
			:class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
    }"
	>
		<div :class="$style.logoContainer" class=" pr-0">
			<div :class="$style.logo">
				<img src="/logo.svg" class="mr-2" alt="Emil Frey Logistics" height="34"/>
				<!--<div :class="$style.name">{{ settings.logo }}</div>-->
				<div v-if="settings.logo === 'EmilFrey'" :class="$style.descr">Emil Frey Logistics</div>
			</div>
		</div>
		<div :class="$style.navigation">
			<a-menu :mode="'horizontal'" :selectedKeys="selectedKeys" @click="handleClick">
				<template v-for="item in menuData">
					<template v-if="!item.roles || item.roles.includes(user.role)">
						<item
							v-if="!item.children && ((!item.hasAllPermissions) || (item.hasAllPermissions && $global.hasAllPermissions(item.hasAllPermissions)))"
							:menu-info="item"
							:styles="$style"
							:key="item.key"
						/>
						<sub-menu
							v-if="(item.children && ((!item.hasAnyPermission) || (item.hasAnyPermission && $global.hasAnyPermission(item.hasAnyPermission))))"
							:menu-info="item"
							:styles="$style"
							:key="item.key"/>
					</template>
				</template>
			</a-menu>
		</div>
	</div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import store from 'store'
    import find from 'lodash/find'
    import {getMenuData} from '../../../../../navigation/base.menu'
    import {getMenuData as getSuperAdminMenu} from '../../../../../navigation/superadmin'
    import {getMenuData as getClientMenu} from '../../../../../navigation/client'
    import {getMenuData as getSupplierMenu} from '../../../../../navigation/supplier'
    import {getMenuData as getSupplierCarrierMenu} from '../../../../../navigation/supplierCarrier'
    import {getMenuData as getSupplierCompoundMenu} from '../../../../../navigation/supplierCompound'
    import {getMenuData as getEFLMenu} from '../../../../../navigation/efl'
    import {getMenuData as getDriver} from '../../../../../navigation/driver'
    import {getMenuData as getDealer} from '../../../../../navigation/dealer'
    import {getMenuData as getManufacturer} from '../../../../../navigation/manufacturer'
    import SubMenu from './partials/submenu'
    import Item from './partials/item'
    import {hasRole} from "../../../../../util/Utils"

    export default {
        name: 'menu-top',
        components: {SubMenu, Item},
        computed: {
            ...mapState(['settings']),
            ...mapGetters('user', ['user']),
        },
        mounted() {
            this.selectedKeys = store.get('app.menu.selectedKeys') || []
            this.setSelectedKeys()
        },
        data() {
            let menu = this.getNavBasedOnRole()
            return {
                menuData: menu,
                url: process.env.VUE_APP_API_URL,
                selectedKeys: [],
                openKeys: [],
            }
        },
        watch: {
            'settings.isMenuCollapsed'() {
                this.openKeys = []
            },
            '$route'() {
                this.setSelectedKeys()
            },
        },
        methods: {
            handleClick(e) {
                if (e.key === 'settings') {
                    this.$store.commit('CHANGE_SETTING', {setting: 'isSettingsOpen', value: true})
                    return
                }
                store.set('app.menu.selectedKeys', [e.key])
                this.selectedKeys = [e.key]
            },
            setSelectedKeys() {
                const pathname = this.$route.path
                // const menuData = this.menuData.concat()
                //
                // const flattenItems = (items, key) =>
                //     items.reduce((flattenedItems, item) => {
                //         flattenedItems.push(item)
                //         if (Array.isArray(item[key])) {
                //             return flattenedItems.concat(flattenItems(item[key], key))
                //         }
                //         return flattenedItems
                //     }, [])
                // const selectedItem = find(flattenItems(menuData, 'children'), [
                //     'url',
                //     pathname,
                // ])
                // this.selectedKeys = selectedItem ? [selectedItem.key] : []
            },
            getNavBasedOnRole() {
                if (hasRole('superadmin')) {
                    return getSuperAdminMenu(this);
                } else if (hasRole('suppliercarrier') && !hasRole('supplierra') && !hasRole('suppliercompound')) {
                    return _.compact(getSupplierCarrierMenu(this));
                } else if (!hasRole('suppliercarrier') && !hasRole('supplierra') && hasRole('suppliercompound')) {
                    return _.compact(getSupplierCompoundMenu(this));
                } else if (hasRole('suppliercarrier') && !hasRole('supplierra') && hasRole('suppliercompound')) {
                    return _.compact(getSupplierMenu(this));
                } else if (hasRole('client')) {
                    return getClientMenu(this);
                } else if (hasRole('efl')) {
                    return getEFLMenu(this);
                } else if (hasRole('driver')) {
                    return getDriver(this);
                } else if (hasRole('dealer')) {
                    return getDealer(this);
                } else if (hasRole('manufacturer')) {
                    return getManufacturer(this);
                }

                return getMenuData(this);
            }
        },
    }
</script>

<style lang="scss" module>
	@import "./style.module.scss";
</style>
