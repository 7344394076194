<template>
    <div>
        <b-row>
            <b-col sm="12">
                <b-alert
                    show
                    @dismissed="handleCloseSystemMessages(data.id)"
                    v-for="(data,index) in getMsgDetails"
                    :key="index"
                    dismissible
                    :variant="data.variant_type">

                    <div v-if="data.system_messages">
                        <span class="text-capitalize">{{data.system_messages.variant_type}}: </span>
                        <span> {{data ? data.system_messages.description : ""}}</span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {request} from "../../util/Request";

    export default {
        data() {
            return {
                getMsgDetails: [],
            }
        },
        methods: {
            async handleSystemMessageDetails() {
                try {
                    const response = await request({
                        method: 'get',
                        url: `dashboards/system/messages/detail`,
                    })
                    const {data} = response
                    this.getMsgDetails = data
                } catch (e) {
                }
            },
            async handleCloseSystemMessages(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `/system/messages/close`,
                        data: {id: id}
                    })
                } catch (e) {
                }
            },
        },
        mounted() {
            this.handleSystemMessageDetails()
        },
    }
</script>
