<template>
    <span>
         <span v-if="type=='inbound_load'">{{$t('title.inboundLoad')}}</span>
         <span v-if="type=='cc_inbound_load'">{{$t('title.compoundToCompoundLoad')}}</span>
         <span v-if="type=='spot_inbound_load'">{{$t('title.singlePickupLoad')}}</span>
         <span v-if="type=='outbound_load'">{{$t('title.outboundLoad')}}</span>
         <span v-if="type=='dealer_to_dealer_load'">{{$t('title.dealerToDealerLoad')}}</span>
         <span v-if="type=='reexport_load'">{{$t('title.reexportLoad')}}</span>
         <span v-if="type=='spot_load'">{{$t('title.spotLoad')}}</span>
    </span>
</template>
<script>
    export default {
        props: ['type', 'label'],
        data() {
            return {}
        }
    }
</script>
